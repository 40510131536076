import React, {
    useState,
} from 'react'
import Button from 'app/shared-components/Button'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import useOnSuccessCreateOrder from 'app/Apps/OrderManagement/Orders/hooks/useOnSuccessCreateOrder'
import {
    OrderTypes,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import ClosePrebooking from 'app/Apps/OrderManagement/Orders/Components/ClosePrebooking'

import {
    BookingDetailsStepType, FinalizeBookingStepType,
} from '../../createCustomerTransportForm.types'
import useCreateCustomerTransportOrder from '../../../hooks/useCreateCustomerTransportOrder'
import useStyles from './ClosePrebookingStep.style'

const ClosePrebookingStep = ({
    setActiveStep,
    order,
    index,
    laneData,
    bookingDetails,
    setIsEdited,
    customerReference,
}: {
    order: FinalizeBookingStepType,
    setActiveStep: (val: number) => void,
    index: number,
    laneData: Lane,
    bookingDetails: BookingDetailsStepType,
    setIsEdited: (isEdited: boolean) => void,
    customerReference: string,
}) => {
    const [
        selectedPrebooking,
        setSelectedPrebooking,
    ] = useState<number>(undefined)

    const {
        freightForwarder,
        orderEntered,
        commentForBillingOps,
        requiredCargoComment,
        estimatedPickup,
        transportModeRoad,
        transportModeForwarder,
        numberOfTrucks,
        numberOfVans,
        originScSelection,
        fwdPosSelection,
        personInCharge,
        mawbNumber,
        hawbNumber,
    } = order

    const {
        classes,
    } = useStyles()

    const onSuccessCreate = useOnSuccessCreateOrder(OrderTypes.CUSTOMER_TRANSPORT)

    const {
        isPending: isLoading,
        mutate,
    } = useCreateCustomerTransportOrder(onSuccessCreate)

    const onClickComplete = () => {
        setIsEdited(false)
        mutate({
            ...bookingDetails,
            lane: {
                id: laneData.id,
            },
            freightForwarder,
            orderEntered,
            commentForBillingOps,
            requiredCargoComment,
            estimatedPickup,
            transportModeRoad,
            transportModeForwarder,
            numberOfTrucks,
            numberOfVans,
            originScSelection,
            fwdPosSelection,
            personInCharge,
            mawbNumber,
            hawbNumber,
            preBooking: selectedPrebooking ? {
                id: selectedPrebooking,
            } : undefined,
        })
    }

    const onClickBack = () => {
        setActiveStep(index - 1)
    }

    return (
        <div>
            <ClosePrebooking
                customerReference={customerReference}
                mawb={mawbNumber}
                selected={selectedPrebooking}
                setSelectedPrebooking={setSelectedPrebooking}
            />
            <div className={classes.actions}>
                <Button
                    label="Complete"
                    onClick={onClickComplete}
                    className={classes.button}
                    saving={isLoading}
                    data-testid="btn-complete"
                />
                <Button
                    label="Back"
                    secondary
                    onClick={onClickBack}
                    className={classes.button}
                    data-testid="btn-back"
                />
            </div>
        </div>
    )
}

export default ClosePrebookingStep
