import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ModificationType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import request from '../../customerTransport.request'

import {
    RelinkLaneType,
} from '../dataUpdate.types'

type RequestParams = {
    orderNumber: string,
    data: Omit<RelinkLaneType, 'modificationType'>,
    modificationType: ModificationType,
}

const useRelinkLane = (onSuccess: () => void)
    : UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRelink,
        onError,
    } = useHandleStatusRequest({
        id: 'RelinkLaneOrder',
        title: 'Relink Lane',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            modificationType,
            orderNumber,
            data,
        }: RequestParams) => {
            return request.RelinkLaneOrder.mutationFn({
                modificationType,
                orderNumber,
                data,
                token,
            })
        },
        onSuccess: onSuccessRelink,
        onError,
    })
}

export default useRelinkLane
