import React, {
    useCallback, useMemo,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import {
    useHistory,
} from 'react-router-dom'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Typography from '@mui/material/Typography'
import Card from 'app/shared-components/Card'
import useLabel from 'app/hooks/useLabel'
import {
    App,
} from 'app/types/common.enums'

import useHasPermission from 'app/hooks/useHasPermission'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    ProductType,
} from 'app/types/enums'
import {
    RequestedLane, RequestedOpportunity,
} from '../../AccountQuotes.types'
import useGetAccountLanesByOpportunities from '../../hooks/useGetAccountLanesByOpportunities'
import useStyles from '../table.styles'
import {
    OpportunityType,
} from '../../../Opportunities/opportunities.types'
import getColumns from './RequestedLanes.columns'

const RequestedLanes = ({
    requestedOpportunities,
    requestedLanes,
    accountQuoteId,
    disabled,
}: {
    requestedOpportunities: (RequestedOpportunity & OpportunityType)[],
    requestedLanes: RequestedLane[],
    accountQuoteId: number,
    disabled: boolean,
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const history = useHistory()
    const getProductTypesAllowedValues = useEnumValues()(ProductType)
    const {
        data: lanes,
        isDataReady: lanesIsReady,
    } = useGetAccountLanesByOpportunities({
        opportunities: requestedOpportunities.map(({
            opportunityNumber,
        }) => {
            return opportunityNumber
        }),
    })

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const load = useCallback(() => {
        if (lanesIsReady) {
            const lanesTableData = lanes.reduce((acc, lane) => {
                const requestedLane = requestedLanes.find((el) => {
                    return el.accountLane?.id === lane.id
                })

                if (!requestedLane) {
                    return acc
                }
                return [
                    ...acc,
                    {
                        ...requestedLane,
                        productTypes: requestedOpportunities?.find((requestedOpportunity) => {
                            return requestedOpportunity.opportunity.opportunityNumber
                                === requestedLane.accountLane.opportunity
                        })?.productTypes?.map((productType) => {
                            return getProductTypesAllowedValues[productType]
                        }),
                        ...lane,
                    },
                ]
            }, [])

            return Promise.resolve({
                data: lanesTableData,
            })
        }
        return Promise.resolve({
            data: [],
        })
    }, [
        lanesIsReady,
        lanes,
        requestedLanes,
        requestedOpportunities,
        getProductTypesAllowedValues,
    ])

    const moveToUpdateTab = () => {
        history.push(`/apps/${App.ACCOUNT_MANAGEMENT}/quotes/all/edit/${accountQuoteId}/data-update`, {
            path: 'overview',
        })
    }

    return (
        <Card
            className={classes.cardWrapper}
        >
            <div className={classes.headerWrapper}>
                <Typography variant="h3">
                    Requested Lanes
                </Typography>
                {(!disabled && accountUpdate) && (
                    <div
                        className={classes.actionWrapper}
                        data-testid="btn-add-lane"
                    >
                        <AddIcon />
                        <a
                            onKeyDown={moveToUpdateTab}
                            onClick={moveToUpdateTab}
                        >
                            Add Existing Lane
                        </a>
                    </div>
                )}
            </div>
            <RichTable
                configName="RequestedLanesTable"
                name="Requested Lanes"
                load={load}
                columns={columns}
                uniqField="id"
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
            />
        </Card>
    )
}

export default RequestedLanes
