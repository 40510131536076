import {
    FilterType,
} from 'app/types/common.enums'
import {
    FilterConfig,
} from 'app/hooks/useFilter'
import {
    ForecastSpreadsheetDataType,
} from '../Forecast.types'

const filterConfig: FilterConfig<keyof Pick<ForecastSpreadsheetDataType,
    'accountName' | 'productType' | 'leaseType' |
    'originAirportIataCode' | 'destinationAirportIataCode'>> = {
        accountName: {
            filterField: 'accountName',
            filterType: FilterType.Array,
        },
        productType: {
            filterField: 'productType',
            filterType: FilterType.Array,
        },
        leaseType: {
            filterField: 'leaseType',
            filterType: FilterType.Array,
        },
        originAirportIataCode: {
            filterField: 'originAirportIataCode',
            filterType: FilterType.Array,
        },
        destinationAirportIataCode: {
            filterField: 'destinationAirportIataCode',
            filterType: FilterType.Array,
        },
    }

export default filterConfig
