import {
    useMemo,
} from 'react'
import uniq from 'lodash/uniq'
import {
    useDispatch,
} from 'react-redux'

import useRequest, {
    RequestKeys, RequestFn,
} from 'app/hooks/useRequest'
import {
    getDateToIso,
} from 'app/utils/date'
import {
    mapLoggerPositions,
} from 'app/utils/optionsLoggers'
import {
    ConfigLogger, LoggerDataTypes,
} from 'app/Apps/Loggers/Loggers.types'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    SensorCode,
} from 'app/types/enums'
import {
    failedToLoadSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

import config from './getSensorData.request'

const LOGGER_TYPES = 'loggerTypes'

type SensorDataResponse = {
    data: {
        data: string,
        positions: string[],
        loggerNumbers: string,
        dataTypes: string,
        sensorCodes: string[],
    }
}

type Params = {
    from: Date,
    to: Date,
    configLoggers?: ConfigLogger[],
    isDateRange: boolean,
    showDoorEvents?: boolean,
    showVirtualLogger?: boolean,
    getAllLoggers: (loggerType: string[]) => {loggerType: string}|{}[],
    url: string,
    additionalQueryData: object,
    customResponseParser?: (result: object, responseSensorData: SensorDataResponse) => object,
    productType?: string,
    useStandardizedTimestamps?: boolean,
    dataTypes?: LoggerDataTypes[]
}

const useGetSensorData = ({
    from,
    to,
    configLoggers = [],
    isDateRange,
    showDoorEvents = false,
    showVirtualLogger = false,
    getAllLoggers,
    url,
    additionalQueryData,
    customResponseParser,
    productType,
    useStandardizedTimestamps = true,
    dataTypes: selectedDataTypes,
}:Params) => {
    const dispatch = useDispatch()
    const getAllowedValues = useEnumValues()
    const loggerType: string[] = useMemo(() => {
        const types = []

        configLoggers.forEach(({
            name,
            options,
            loggerType: type,
        }) => {
            if (type) {
                types.push(type)
            }
            if (options?.length && name === LOGGER_TYPES) {
                options.forEach((option) => {
                    if (option) {
                        types.push(option.value)
                    }
                })
            }
        })
        return uniq(types)
    }, [configLoggers])

    const fromDate = useMemo(() => {
        return new Date(from)
    }, [from])

    const toDate = useMemo(() => {
        return new Date(to)
    }, [to])

    const queryDataTypes = selectedDataTypes?.length
        ? selectedDataTypes : [LoggerDataTypes.TEMPERATURE]

    const queryData = JSON.stringify({
        useStandardizedTimestamps,
        from: getDateToIso(fromDate, isDateRange),
        to: getDateToIso(toDate, isDateRange),
        loggers: getAllLoggers(loggerType),
        dataTypes: showDoorEvents ? [
            ...queryDataTypes,
            LoggerDataTypes.DOOR,
        ] : queryDataTypes,
        productType,
        ...additionalQueryData,
        isVirtual: showVirtualLogger,
    })

    const {
        data: responseSensorData,
    } = useRequest<SensorDataResponse>({
        key: RequestKeys.getSensorData,
        params: {
            data: JSON.parse(queryData),
            url,
        },
        requestFunc: RequestFn.getEntity(config.requestFn(), (promise) => {
            return promise
                .then((resp) => {
                    return resp
                })
                .catch((err) => {
                    const {
                        response: {
                            data: errors,
                            status: statusCode,
                        },
                    } = err

                    dispatch(
                        failedToLoadSaveForm({
                            id: 'sensorDataContainer',
                            errors: errors.errors || errors,
                            statusCode,
                        }),
                    )
                })
        }),
        keepPreviousData: true,
        enabled: true,
    })

    const parseSensorData = useMemo(() => {
        if (!responseSensorData) {
            return {}
        }
        const {
            data: {
                data: sensorData,
                positions: sensorLabels,
                loggerNumbers,
                dataTypes,
                sensorCodes: sensorCodesData,
            },
        } = responseSensorData

        const allowedSensorCodes = getAllowedValues(SensorCode)

        const result = {
            sensorData,
            positions: sensorLabels.map(mapLoggerPositions),
        }

        if (customResponseParser) {
            return customResponseParser(result, responseSensorData)
        }

        return {
            ...result,
            sensorLabels: sensorCodesData.map((item, index) => {
                return `${allowedSensorCodes[item]} ${loggerNumbers[index]}`
            }),
            sensorCodes: sensorCodesData.map((item, index) => {
                return `${item}_${dataTypes[index]}_${loggerNumbers[index]}`
            }),
        }
    }, [
        getAllowedValues,
        responseSensorData,
        customResponseParser,
    ])

    return {
        sensorData: {
            data: parseSensorData,
        },
    }
}

export default useGetSensorData
