import React, {
    useState, useCallback,
} from 'react'
import noop from 'lodash/noop'

import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useStyles from './CompanyForm.style'
import companyFields from './CompanyForm.fields'
import {
    Company,
} from '../Companies.types'
import CompanyDuplicationCheckDialog from '../CompanyDuplicationCheckDialog'
import useCompanyDuplicationCheck from '../hooks/useCompanyDuplicationCheck'

type CompanyFormProps = {
    name: string,
    data?: Company,
    onCancel?: () => void,
    'data-testid'?: string,
    customButtons?: JSX.Element[],
    action?: (...args: any[]) => void,
}

const defaultProps: Partial<CompanyFormProps> = {
    data: {} as Company,
    onCancel: undefined,
    action: noop,
    'data-testid': 'companyform',
    customButtons: undefined,
}

const CompanyForm = ({
    data,
    name,
    action,
    onCancel,
    'data-testid': dataTestid,
    customButtons,
} : CompanyFormProps) => {
    const {
        classes,
    } = useStyles()
    const exists = Boolean(data?.id)

    const [
        duplicationData,
        setDuplicationData,
    ] = useState(null)

    const closeConfirmationDialog = useCallback(() => {
        setDuplicationData(null)
    }, [setDuplicationData])

    const [
        value,
        setValue,
    ] = useState(data)

    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel()
            return
        }
        setValue(data)
    }, [
        onCancel,
        data,
    ])

    const fields = useFieldsWithClassName(
        companyFields, classes,
    )

    const onCheckDuplicateSuccess = useCallback((duplicates) => {
        if (!duplicates?.length) {
            action(value)
            return
        }

        setDuplicationData(duplicates)
    }, [
        setDuplicationData,
        action,
        value,
    ])

    const checkDuplicate = useCompanyDuplicationCheck(onCheckDuplicateSuccess)

    const callAction = useCallback(() => {
        if (!exists) {
            checkDuplicate({
                companyName: value?.companyName,
                awbPrefix: value?.awbPrefix,
            })

            return
        }

        action(value)
    }, [
        checkDuplicate,
        action,
        value,
        exists,
    ])

    return (
        <div
            className={classes.root}
        >
            <DomainObjectForm
                data-testid={dataTestid}
                name={name}
                value={value}
                fields={fields}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                exists={exists}
                onChange={setValue}
            />
            <ButtonContainer>
                <Button
                    label={exists ? 'Save' : 'Create'}
                    onClick={callAction}
                />
                { customButtons || null }
                <Button
                    secondary
                    label="Cancel"
                    onClick={handleCancel}
                />
            </ButtonContainer>
            <CompanyDuplicationCheckDialog
                createCompany={() => { return action(value) }}
                data={duplicationData}
                close={closeConfirmationDialog}
            />
        </div>
    )
}

CompanyForm.defaultProps = defaultProps

export default CompanyForm
