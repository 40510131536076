import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            margin: `${theme.spacing(2)} 0`,
        },
        checkbox: {
            padding: '0px !important',
        },
        input: {
            backgroundColor: theme.palette.secondary[50],
            paddingLeft: theme.spacing(0.25),
        },
        inputLabel: {
            display: 'none',
        },
        tableCellHead: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        tableCell: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'right',
            '&:nth-of-type(7)': {
                textAlign: 'right',
            },
        },
        btnContainer: {
            width: 'fit-content',
            display: 'inline-flex',
        },
        classNameLabel: {
            flexBasis: 'content',
        },
    }
})

export default useStyles
