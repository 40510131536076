import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'

export default ({
    onSuccess,
}: {
    onSuccess: (...args: any[]) => void,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessLaneStatusUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane was awarded',
        id: 'AwardLane',
        onSuccess,
    })

    const {
        mutate: award,
    } = useMutation({
        mutationFn: (id: number) => {
            return simpleApi({
                ...requests.award({
                    id,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLaneStatusUpdate,
    })

    return useCallback((id: number) => {
        award(id)
    }, [award])
}
