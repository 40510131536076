import removeUnauthorizedField from 'app/utils/removeUnauthorizedField'
import {
    DTOCardFormField, FieldInCard, FormPart,
} from 'app/types/form.types'

import {
    Fee, LaneFeesDetail,
} from 'app/Apps/Pricing/Pricing.types'

import {
    LaneFeesStatus, RangeType,
} from 'app/types/enums'

import {
    RejectedReasons,
    ReturnedReasons,
} from 'app/shared-components/LaneFees/LaneFeesForm/reasons.types'

type Args = {
    ccy: string,
    fxRate: { id?: number },
    fees: Fee[],
    status: string,
    exists: boolean,
    hidefxRate: boolean,
    tempRange: string,
    originalStatus: string,
    editingNotAllowed: boolean,
    getAllowedValues: (enumType: Record<string, string>, show: boolean) => Record<string, string>,
    onReviewLaneFeesReturnReasonClick: () => void,
    hasReturnReeason?: boolean,
}

type LaneFeesFormFields = keyof LaneFeesDetail

const getLaneFeesFields = ({
    ccy,
    fxRate,
    fees,
    status,
    exists,
    hidefxRate,
    tempRange,
    originalStatus,
    editingNotAllowed,
    getAllowedValues,
    onReviewLaneFeesReturnReasonClick,
    hasReturnReeason,
}: Args):
    FormPart<LaneFeesFormFields>[] => {
    const notesDisabled = (
        editingNotAllowed
            && originalStatus !== LaneFeesStatus.AWARDED
            && originalStatus !== LaneFeesStatus.CANCELLED
            && originalStatus !== LaneFeesStatus.SUBMITTED
            && originalStatus !== LaneFeesStatus.REJECTED
    )
    const generalFields: FieldInCard<LaneFeesFormFields>[] = [
        {
            id: 'column1',
            fields: removeUnauthorizedField([
                {
                    name: 'laneFeesStatus',
                    labelKey: 'LaneFeesUpdateDto.laneFeesStatus',
                    componentName: 'StatusTransitionSelector',
                    originalStatus,
                    allowedValues: getAllowedValues(LaneFeesStatus, true),
                    useDropdown: true,
                    disabled: !exists || (editingNotAllowed
                        && originalStatus !== LaneFeesStatus.SUBMITTED
                        && originalStatus !== LaneFeesStatus.REJECTED),
                    required: true,
                },
                {
                    name: 'validFrom',
                    labelKey: 'LaneFeesUpdateDto.validFrom',
                    componentName: 'DateSelect',
                    disabled: editingNotAllowed,
                    required: true,
                },
                {
                    name: 'validTo',
                    labelKey: 'LaneFeesUpdateDto.validTo',
                    componentName: 'DateSelect',
                    disabled: editingNotAllowed,
                },
                {
                    name: 'reviewLaneFeesReturnReason',
                    componentName: 'LinkToClick',
                    onClick: onReviewLaneFeesReturnReasonClick,
                    labelKey: 'LaneFees.reviewLaneFeesReturnReason',
                },
                {
                    name: 'pricingNotes',
                    labelKey: 'LaneFeesUpdateDto.pricingNotes',
                    componentName: 'LongText',
                    rows: 2,
                    disabled: notesDisabled,
                },
            ], ['reviewLaneFeesReturnReason'], hasReturnReeason),
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'returnReason',
                    labelKey: 'LaneFeesUpdateDto.rejectedReason',
                    componentName: 'EnumSelector',
                    required: true,
                    useDropdown: true,
                    allowedValues: getAllowedValues(ReturnedReasons, false),
                    noSort: true,
                },
                {
                    name: 'returnReasonComment',
                    className: 'rejectedReasonComment',
                    componentName: 'LongText',
                    labelKey: 'LaneFeesUpdateDto.returnReasonComment',
                    required: true,
                },
                {
                    name: 'rejectedReason',
                    labelKey: 'LaneFeesUpdateDto.rejectedReason',
                    componentName: 'EnumSelector',
                    required: true,
                    allowedValues: getAllowedValues(RejectedReasons, false),
                    noSort: true,
                },
                {
                    name: 'rejectedReasonComment',
                    className: 'rejectedReasonComment',
                    componentName: 'LongText',
                    labelKey: 'LaneFeesUpdateDto.rejectedReasonComment',
                    required: true,
                },
                {
                    name: 'rejectedReasonHint',
                    labelKey: 'LaneFeesUpdateDto.rejectedReason',
                    componentName: 'LongText',
                    className: 'rejectedReasonHint',
                    noLabel: true,
                    disabled: true,
                },
                {
                    name: 'rentalDays',
                    labelKey: 'LaneFeesUpdateDto.rentalDays',
                    componentName: 'IntegerInput',
                    disabled: editingNotAllowed,
                },
                {
                    name: 'logisticsDays',
                    labelKey: 'LaneFeesUpdateDto.logisticsDays',
                    componentName: 'IntegerInput',
                    disabled: editingNotAllowed,
                },
                {
                    name: 'minOrder',
                    labelKey: 'LaneFeesUpdateDto.minOrder',
                    className: 'minOrder',
                    componentName: 'IntegerInput',
                    disabled: editingNotAllowed,
                },
                {
                    name: 'supportingDocuments',
                    labelKey: 'LaneFeesUpdateDto.supportingDocuments',
                    componentName: 'AttachmentsObjectSelector',
                    disabled: editingNotAllowed,
                },
            ],
        },
        {
            id: 'column3',
            fields: [
                {
                    name: 'commentForCustomer',
                    componentName: 'LongText',
                    labelKey: 'LaneFeesDto.commentForCustomer',
                    disabled: notesDisabled,
                },
                {
                    name: 'commentForBilling',
                    componentName: 'LongText',
                    labelKey: 'LaneFeesDto.commentForBilling',
                    disabled: editingNotAllowed,
                },
                {
                    name: 'requiresManualBillingReview',
                    componentName: 'Checkbox',
                    labelKey: 'OrderBillingInformationView.requiresManualBillingReview',
                    disabled: editingNotAllowed,
                },
                {
                    name: 'isPerformanceQualification',
                    componentName: 'Checkbox',
                    labelKey: 'Order.isPerformanceQualification',
                    disabled: editingNotAllowed,
                },
            ],
        },
    ]

    const tempReasonFields:
        FieldInCard<LaneFeesFormFields>[] = generalFields.map((currentFields) => {
            if (currentFields.id === 'column2') {
                return {
                    ...currentFields,
                    fields: removeUnauthorizedField(
                        removeUnauthorizedField(currentFields.fields, [
                            'rejectedReason',
                            'rejectedReasonHint',
                            'rejectedReasonComment',
                        ], status === LaneFeesStatus.REJECTED),
                        [
                            'returnReason',
                            'returnReasonComment',
                        ],
                        originalStatus === LaneFeesStatus.SUBMITTED
                            && status === LaneFeesStatus.IN_PROGRESS,
                    ),
                }
            }
            return currentFields
        })

    const fxRateField: FieldInCard<LaneFeesFormFields> = {
        title: 'FX Rates',
        className: 'fxRate',
        fields: [{
            componentName: 'FxRateObjectWithSelect',
            labelKey: 'LaneFeesUpdateDto.fxRate',
            name: 'fxRate',
            ccy,
            disabled: editingNotAllowed,
        }],
    }

    const generalSectionFields:
        DTOCardFormField<LaneFeesFormFields> = {
            title: 'General',
            className: 'general',
            fields: tempReasonFields,
        }

    const defaultFields: FormPart<LaneFeesFormFields>[] = hidefxRate
        ? [generalSectionFields] : [
            generalSectionFields,
            fxRateField,
        ]

    const feeDays: DTOCardFormField<LaneFeesFormFields>[] = [{
        id: 'FeeDays',
        title: 'Fee Days',
        className: 'feeDays',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'expressOrderTimeline',
                        componentName: 'IntegerInput',
                        disabled: editingNotAllowed,
                        required: true,
                        labelKey: 'LaneGroupCreateOrDuplicateDto.expressOrderTimeline',
                    },
                    {
                        name: 'expressDaysType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RangeType, false),
                        useDropdown: true,
                        disabled: editingNotAllowed,
                        required: true,
                        labelKey: 'LaneGroupCreateOrDuplicateDto.expressDaysType',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'postponementFeeTimeline',
                        componentName: 'IntegerInput',
                        disabled: editingNotAllowed,
                        required: true,
                        labelKey: 'LaneGroupCreateOrDuplicateDto.postponementFeeTimeline',
                    },
                    {
                        name: 'postponementDaysType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RangeType, false),
                        useDropdown: true,
                        disabled: editingNotAllowed,
                        required: true,
                        labelKey: 'LaneGroupCreateOrDuplicateDto.postponementDaysType',
                    },
                ],
            },
        ],
    }]

    const truckingCapacity: FieldInCard<LaneFeesFormFields> = {
        id: 'truckingCapacity',
        title: 'Max Containers Per Truck / Van',
        className: 'truckingCapacity',
        fields: [{
            name: 'truckingCapacity',
            labelKey: 'LaneFeesFromLaneGroupView.truckingCapacity',
            componentName: 'TruckCapacity',
            tempRange,
            disabled: editingNotAllowed,
        }],
    }

    const feesField: FieldInCard<LaneFeesFormFields> = {
        title: 'Fees',
        className: 'fees',
        fields: [{
            name: 'fees',
            componentName: 'Fees',
            labelKey: 'LaneFeesUpdateDto.fees',
            fxRate,
            currency: ccy,
            tempRange,
            value: fees,
            disabled: editingNotAllowed,
            showSkyNetSpreadSheet: true,
        }],
    }

    return exists
        ? [
            ...defaultFields,
            truckingCapacity,
            ...feeDays,
            feesField,
        ] : [
            ...defaultFields,
            ...feeDays,
        ]
}

export default getLaneFeesFields
