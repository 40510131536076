import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    Company,
} from './Companies.types'

const config: SkyNetTableConfig<keyof Company> = {
    tabs: [{
        name: 'All Companies',
        url: 'all',
    }],
    fields: [
        {
            name: 'companyName',
            reduced: true,
        },
        {
            name: 'hasAccount',
            labelKey: 'Company.hasAccount',
            componentName: 'ChipCheckMark',
        },
        {
            name: 'website',
            reduced: true,
        },
        {
            name: 'createdBy',
            reduced: true,
            labelKey: 'CompanyView.createdBy',
        },
    ],
}

export default config
