import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import downloadDocument from 'app/utils/downloadDocument'
import config from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/utils/OrderNotifications.config'
import simpleApi from 'app/services/simpleApi'

const useDownloadNotificationEmail = ({
    orderNumber,
    id,
    notificationName,
    value,
}:{
    orderNumber: string,
    id: number,
    notificationName: string,
    value: string,
}) => {
    const token = useJWTToken()

    return useMutation({
        mutationFn: () => {
            return simpleApi({
                ...config.NotificationEmail.requestFn({
                    id,
                    orderNumber,
                }),
                method: 'GET',
                token,
            })
        },
        onSuccess: (data: unknown) => {
            downloadDocument({
                headers: {
                    'content-type': 'text/html;charset=UTF-8',
                },
                data,
            }, `${notificationName}${value}`)
        },
    })
}

export default useDownloadNotificationEmail
