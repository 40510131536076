import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    CompanyWithParent,
} from '../Companies.types'

const fields: DTOCardFormField<keyof CompanyWithParent>[] = [{
    id: 'summary',
    title: 'Summary',
    className: 'summary',
    fields: [
        {
            id: 'column1',
            fields: [
                {
                    name: 'companyName',
                    required: true,
                    labelKey: 'Company.companyName',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'locationNameRule',
                    componentName: 'InputRule',
                    rule: 'Official name',
                    example: 'Kuehne & Nagel AG',
                    labelKey: 'Company.locationNameRule',
                },
                {
                    name: 'website',
                    componentName: 'InputSingleline',
                    labelKey: 'Company.website',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'awbPrefix',
                    componentName: 'InputSingleline',
                    type: 'number',
                    labelKey: 'Company.awbPrefix',
                },
                {
                    name: 'parentCompany',
                    componentName: 'CompanyDataSelector',
                    labelKey: 'Company.parentCompany',
                },
            ],
        },
    ],
}]

export default fields
