import {
    RequestConfig,
} from 'app/types/request.types'
import {
    ForecastType,
} from './Forecast.types'

type ForecastRequests = {
    get: ({
        id: number,
    }) => RequestConfig,
    getForecastOngoingOpportunities: () => RequestConfig,
    getAll: (params: Record<string, any>) => RequestConfig,
    bulkUpdate: ({
        data,
    }: {
        data: ForecastType[]
    }) => RequestConfig,
    forecastUpdate: ({
        id,
    }: {id: number}) => RequestConfig,
    filterCount: ({
        fieldName: string,
    }) => RequestConfig,
    getCurrentMonth: ({
        id,
    }: {id: number}) => RequestConfig,
    updateCurrentMonth: ({
        id,
    }: {id: number}) => RequestConfig,
}

const forecastRequests: ForecastRequests = {
    get: ({
        id,
    }) => {
        return {
            url: `opportunity/forecast/${id}`,
            method: 'GET',
        }
    },
    getForecastOngoingOpportunities: () => {
        return {
            url: 'opportunity/forecast/filter-request/ongoing-opportunities',
            method: 'POST',
        }
    },
    getAll: (params) => {
        return {
            url: 'opportunity/forecast/filter-request/submission',
            method: 'POST',
            ...params,
        }
    },
    bulkUpdate: ({
        data,
    }) => {
        return {
            url: 'opportunity/forecast/submission',
            method: 'POST',
            data,
        }
    },
    forecastUpdate: ({
        id,
    }) => {
        return {
            url: `opportunity/forecast/${id}`,
            method: 'PATCH',
        }
    },
    filterCount: ({
        fieldName,
    }) => {
        return {
            url: `opportunity/forecast/filter-request/submission/count/${fieldName}`,
            method: 'POST',
            data: {
                rangeType: '',
            },
        }
    },
    getCurrentMonth: ({
        id,
    }) => {
        return {
            url: `opportunity/forecast/${id}/current-month`,
            method: 'GET',
        }
    },
    updateCurrentMonth: ({
        id,
    }) => {
        return {
            url: `opportunity/forecast/${id}/current-month`,
            method: 'PATCH',
        }
    },
}

export default forecastRequests
