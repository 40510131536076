/* eslint-disable complexity */
import React from 'react'
import {
    Grid,
    Typography,
} from '@mui/material'
import get from 'lodash/get'

import useLabel from 'app/hooks/useLabel'
import Card from 'app/shared-components/Card'
import TextSingleline from 'app/shared-components/TextSingleline'
import Label from 'app/shared-components/Label'
import StatusHandler from 'app/shared-components/StatusHandler'
import CompanyDataSelector from 'app/shared-components/CompanySelector/CompanyDataSelector'
import ChipCheckMark from 'app/Apps/DomainObject/ComponentMapper/ChipCheckMark/ChipCheckMark'

import Link from './Link'
import BillingAddresses from './BillingAddresses/BillingAddresses'
import Addresses from './Addresses'
import ParentCompanySubsidiaries from './ParentCompanySubsidiaries'
import useStyles from './CompanyOverview.styles'
import useGetCompany from '../hooks/useGetCompany'

const CompanyOverview = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetCompany(id)

    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const mainAddress = get(data, 'addresses', []).find((address) => { return address.isMainAddress })
    const mainAddressCountry = get(mainAddress, 'country.countryName', '')

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div className={classes.container}>
                { data?.id ? (
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <Card
                                title="Summary"
                                fullHeight
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={3}
                                    >
                                        <TextSingleline
                                            title={l('Company.companyName')}
                                            value={data.companyName}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={3}
                                    >
                                        <Label
                                            title={l('Company.hasAccount')}
                                        />
                                        <div
                                            className={classes.chip}
                                        >
                                            <ChipCheckMark
                                                value={data.hasAccount}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={3}
                                    >
                                        <div>
                                            <Label title={l('Company.website')} />
                                            {data?.website ? <Link website={data.website} /> : '-'}
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={3}
                                    >
                                        <CompanyDataSelector
                                            title={l('Company.parentCompany')}
                                            value={data.parentCompany}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        {Boolean(data?.parentCompany?.id) && (
                            <ParentCompanySubsidiaries
                                id={data.parentCompany.id}
                            />
                        )}
                        <Addresses
                            data={data}
                        />
                        <BillingAddresses
                            data={data}
                        />
                        {mainAddress
                        && (
                            <Grid
                                item
                                xs={12}
                                lg={3}
                            >
                                <Card
                                    title="Main Address"
                                    fullHeight
                                >
                                    <div>
                                        <Label title="Main Address" />
                                        <Typography className={classes.text}>
                                            {mainAddress.addressName}

                                        </Typography>
                                        <Typography className={classes.text}>
                                            {mainAddress.addressLine1}
                                        </Typography>
                                        <Typography className={classes.text}>
                                            {`${mainAddress.zip} ${mainAddress.city}`}
                                        </Typography>
                                        <Typography className={classes.text}>
                                            {mainAddressCountry}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                ) : null}
            </div>
        </StatusHandler>
    )
}

export default CompanyOverview
