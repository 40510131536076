import {
    FilterType,
} from 'app/types/common.enums'
import {
    convertFilterValueToObjectSelector, convertObjectValueToFilter,
    FilterConfig,
} from 'app/hooks/useFilter'
import {
    OpportunityType,
} from '../opportunities.types'

const filterConfig: FilterConfig<keyof Omit<OpportunityType,
    'annualPalletVolume' | 'businessStart' | 'id' |
    'opportunityComment' | 'opportunityNumber' | 'opportunityStatus' |
    'validationNeeded' | 'productType' | 'archived' | 'regionalGroup' |
    'originRegion' | 'destinationRegion' | 'accountName' | 'existNonCanceledLanes' | 'reasonForWonLost'>> = {
        account: {
            filterField: 'accountId',
            filterType: FilterType.Array,
            preselectOptions: {
                componentName: 'AccountSelector',
                filterToValueConverter: convertFilterValueToObjectSelector,
                onChangeConverter: convertObjectValueToFilter,
                placeholder: 'Select Account',
                noneOption: false,
                requestParams: {
                    filters: {
                        existsInRegionalGroups: true,
                    },
                },
            },
        },
        temperatureRange: {
            filterField: 'temperatureRange',
            filterType: FilterType.Array,
        },
        frequency: {
            filterField: 'frequency',
            filterType: FilterType.Array,
        },
        airline: {
            filterField: 'airlineName',
            filterType: FilterType.Array,
        },
        reprOriginAirport: {
            filterField: 'reprOriginAirport',
            filterType: FilterType.Array,
        },
        reprDestinationAirport: {
            filterField: 'reprDestinationAirport',
            filterType: FilterType.Array,
        },
        palletType: {
            filterField: 'palletType',
            filterType: FilterType.Array,
        },
        competitorsPackaging: {
            filterField: 'competitorsPackaging',
            filterType: FilterType.Array,
        },
        currentPackagingSolution: {
            filterField: 'currentPackagingSolution',
            filterType: FilterType.Array,
        },
    }

export default filterConfig
