import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    BookingsWaitingForPricing,
} from './openBookings.types'
import {
    ProductType,
} from '../../../types/enums'

const config: SkyNetTableConfig<keyof BookingsWaitingForPricing> = {
    name: 'BookingsWaitingForPricing',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'preBookingNumber',
            labelKey: 'Prebooking.number',
            sorting: false,
        },
        {
            name: 'laneNumber',
            labelKey: 'Prebooking.lane',
        },
        {
            name: 'originServiceCenter',
            labelKey: 'Prebooking.originServiceCenter',
            filterField: 'originServiceCenter',
            filterType: FilterType.Array,
        },
        {
            name: 'customerExecution',
            labelKey: 'Prebooking.customerExecution',
            filterField: 'customerExecution',
            filterType: FilterType.Array,
        },
        {
            name: 'pricingAccountCompanyName',
            labelKey: 'Prebooking.pricingAccount',
            filterField: 'pricingAccountCompanyName',
            filterType: FilterType.Array,
        },
        {
            name: 'originAirportIataCode',
            labelKey: 'OrderBaseTabView.from',
            filterField: 'originAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            name: 'destinationAirportIataCode',
            labelKey: 'OrderBaseTabView.to',
            filterField: 'destinationAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedHandover',
            labelKey: 'Prebooking.requestedHandover',
            filterField: 'requestedHandover',
            filterType: FilterType.Array,
        },
        {
            name: 'requestedPickup',
            labelKey: 'Prebooking.requestedPickup',
            filterField: 'requestedPickup',
            filterType: FilterType.Array,
        },
        {
            name: 'mawbNumber',
            filterField: 'mawbNumber',
            filterType: FilterType.Array,
            labelKey: 'Prebooking.mawbNumber',
        },
        {
            name: 'customerReference',
            labelKey: 'Prebooking.customerReference',
            filterField: 'customerReference',
            filterType: FilterType.Array,
        },
        {
            name: 'requiredContainers',
            componentName: 'RequiredContainersList',
            allowedValuesEnum: ProductType,
            sorting: false,
            labelKey: 'Prebooking.requiredContainers',
        },
    ],
}

export default config
