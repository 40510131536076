import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import request from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

const useLaneUpdate = ({
    id,
    onSuccess,
}: {
    onSuccess: () => void,
    id: number,
}) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'LaneLogistics',
        title: 'Update Lane',
    })

    return useMutation({
        mutationFn: (data: Lane) => {
            return request.Lane.mutationFn({
                id,
                data: {
                    ...data,
                },
                token,
            })
        },
        onSuccess,
        onError,
    })
}

export default useLaneUpdate
