import {
    useCallback,
} from 'react'
import {
    useQuery,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import simpleApi from 'app/services/simpleApi'
import AttachmentType from '../Attachment.types'

const useGetFiles = (value?: AttachmentType[] | number[]) => {
    const token = useJWTToken()
    const queryFn = useCallback(() => {
        return simpleApi({
            url: 'attachment/filter-request/all', // TODO: TO request file
            token,
            method: 'POST',
            data: {
                includeFilters: {
                    id: value,
                },
            },
        })
    }, [
        token,
        value,
    ])

    return useQuery({
        queryKey: [
            RequestKeys.getAttachments,
            value,
        ],
        queryFn,
        enabled: Boolean(value),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
        refetchInterval: false,
    })
}

export default useGetFiles
