import {
    TemperatureRange,
    OpportunityFrequency,
    OpportunityStatus,
    OpportunityPalletType,
    ProductType,
    RegionFirstLevel,
} from 'app/types/enums'

import {
    Account,
} from '../AccountManagement.types'
import {
    RegionalGroup,
} from '../RegionalGroups/regionalGroups.types'

export enum ReasonForWonLost {
    PRICE = 'PRICE',
    POSITIONING_COLLECTION = 'POSITIONING_COLLECTION',
    AVAILABILITY = 'AVAILABILITY',
    PERFORMANCE = 'PERFORMANCE',
    QUALITY = 'QUALITY ',
    SUSTAINABILITY = 'SUSTAINABILITY',
    STRATEGIC_CLIENT_DECISION = 'STRATEGIC_CLIENT_DECISION',
    REFERRAL = 'REFERRAL',
    VALIDATION_CHALLENGES = 'VALIDATION_CHALLENGES',
    COMPETITION_PARTNERSHIPS = 'COMPETITION_PARTNERSHIPS',
    ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
    OTHERS = 'OTHERS',
}

export enum Packaging {
    CSAFE_RAP = 'CSAFE_RAP',
    CSAFE_RKN = 'CSAFE_RKN',
    DOKASCH_OPTICOOLER_RAP = 'DOKASCH_OPTICOOLER_RAP',
    DOKASCH_OPTICOOLER_RKN = 'DOKASCH_OPTICOOLER_RKN',
    ENVIROTAINER_RAP_E2 = 'ENVIROTAINER_RAP_E2',
    ENVIROTAINER_RAP_T2 = 'ENVIROTAINER_RAP_T2',
    ENVIROTAINER_RELEYE_RLP = 'ENVIROTAINER_RELEYE_RLP',
    ENVIROTAINER_RKN_E1 = 'ENVIROTAINER_RKN_E1',
    ENVIROTAINER_RKN_T2 = 'ENVIROTAINER_RKN_T2',
    OTHER = 'OTHER',
    PELI_COOLPALL_FLEX = 'PELI_COOLPALL_FLEX',
    PELI_COOLPALL_VERTOS = 'PELI_COOLPALL_VERTOS',
    PELI_COOLPALL_VERTOS_ADVANCED = 'PELI_COOLPALL_VERTOS_ADVANCED',
    PELI_CREDO_CARGO = 'PELI_CREDO_CARGO',
    PELI_CREDO_XTREME_FULL_STACK_PALLET = 'PELI_CREDO_XTREME_FULL_STACK_PALLET',
    PELI_CREDO_XTREME_HALF_STACK_PALLET = 'PELI_CREDO_XTREME_HALF_STACK_PALLET',
    SOFRIGAM = 'SOFRIGAM',
    SOFTBOX = 'SOFTBOX',
    TOWER_EURO_PALLET = 'TOWER_EURO_PALLET',
    TOWER_KT400D_60_DEEP_FROZEN_200L = 'TOWER_KT400D_60_DEEP_FROZEN_200L',
    TOWER_KTM42D_DEEP_FROZEN_DOUBLE_PALLET = 'TOWER_KTM42D_DEEP_FROZEN_DOUBLE_PALLET',
    TOWER_SUB_PALLET = 'TOWER_SUB_PALLET',
    TOWER_US_PALLET = 'TOWER_US_PALLET',
    VA_Q_PAL = 'VA_Q_PAL',
    VA_Q_TAINER_EUROX = 'VA_Q_TAINER_EUROX',
    VA_Q_TAINER_TWINX = 'VA_Q_TAINER_TWINX',
    VA_Q_TAINER_USX = 'VA_Q_TAINER_USX',
    VA_Q_TAINER_XLX = 'VA_Q_TAINER_XLX',
    UNKNOWN = 'UNKNOWN',
}

export type OpportunityTableType = {
    opportunityNumber: string,
    companyName: string,
    temperatureRange: TemperatureRange,
    originRegion: RegionFirstLevel,
    destinationRegion: RegionFirstLevel,
    reprOriginAirport: string,
    reprDestinationAirport: string,
    opportunityComment: string,
    palletType: OpportunityPalletType,
    id: number,
    opportunityStatus: OpportunityStatus,
    changedOn: string,
}

export type OpportunityType = {
    opportunityNumber: string,
    account: Account,
    temperatureRange: TemperatureRange,
    opportunityComment: string,
    businessStart: string,
    airline: {id: number},
    reprOriginAirport: {id: number},
    reprDestinationAirport: {id: number},
    annualPalletVolume: number,
    competitorsPackaging: Packaging[],
    currentPackagingSolution: Packaging,
    validationNeeded: boolean,
    id: number,
    frequency: OpportunityFrequency,
    palletType: OpportunityPalletType,
    productType?: ProductType,
    archived?: boolean,
    opportunityStatus: OpportunityStatus,
    regionalGroup: RegionalGroup,
    accountName?: string
    originRegion?: RegionFirstLevel,
    destinationRegion?: RegionFirstLevel,
    existNonCanceledLanes?: boolean,
    reasonForWonLost?: ReasonForWonLost[],
}

export type OpportunityBulkEditType = {
    id: 0,
    changedOn: string,
    accountName: string,
    accountId: number,
    temperatureRange: TemperatureRange,
    frequency: OpportunityFrequency,
    airlineName: string,
    airlineId: 0,
    businessStart: string,
    reprOriginAirport: string,
    reprOriginAirportId: number,
    reprDestinationAirport: string,
    reprDestinationAirportId: number,
    annualPalletVolume: number,
    competitorsPackaging: Packaging[],
    currentPackagingSolution: Packaging,
    palletType: OpportunityPalletType,
    validationNeeded: boolean,
    opportunityComment: string,
    regionalGroupId: number,
    regionalGroupNumber: string,
    regionalGroupDestinationRegion: RegionFirstLevel,
    regionalGroupOriginRegion: RegionFirstLevel,
    existNonCanceledLanes: boolean,
}
