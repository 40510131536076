import {
    useCallback,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import {
    PreBookingReasonForChange,
} from '../../../PreBooking.types'
import requests from '../../../PreBookings.requests'

const usePreBookingUpdate = ({
    onSuccess,
    data,
}) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessPreBookingUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Pre booking was updated',
        id: 'PreBookingUpdated',
        onSuccess,
    })

    const {
        mutate: updatePreBooking,
    } = useMutation({
        mutationFn: (reason: PreBookingReasonForChange) => {
            return simpleApi({
                ...requests.update({
                    data,
                    id: data.id,
                    reason,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessPreBookingUpdate,
    })

    return useCallback(async (reason: PreBookingReasonForChange) => {
        return updatePreBooking(reason)
    }, [updatePreBooking])
}

export default usePreBookingUpdate
