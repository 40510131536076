import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../../toBeAssignedLanes.request'

type RequestParams = {
    lanes: {id: number}[],
    opportunity: {id: number},
}

const useAssignmentToOpportunity = (onAssigned: () => void)
    : UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessAssignment,
        onError,
    } = useHandleStatusRequest({
        id: 'AssignmentToOpportunity',
        onSuccess: onAssigned,
        onError: onAssigned,
    })

    return useMutation({
        mutationFn: (data: RequestParams) => {
            return request.AssignmentToOpportunity.mutationFn({
                data,
                token,
            })
        },
        onSuccess: onSuccessAssignment,
        onError,
    })
}

export default useAssignmentToOpportunity
