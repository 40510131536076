import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.requests'

export default ({
    id, onSuccess,
}: {
    id: number,
    onSuccess: (...args: any[]) => void
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessAssigning,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane was successfully assigned to Lane Group',
        id: 'LaneAssignedToLaneGroup',
        onSuccess,
    })

    const {
        mutate: assignToLaneGroup,
    } = useMutation({
        mutationFn: (laneId: number) => {
            return simpleApi({
                ...requests.assignToLaneGroup({
                    id: laneId,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessAssigning,
    })

    return useCallback(() => {
        assignToLaneGroup(id)
    }, [
        assignToLaneGroup,
        id,
    ])
}
