import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import simpleApi from 'app/services/simpleApi'

import config from '../contactSelector.config'

const ORDER_MGMT_TYPE = 'NOTIFICATION_ORDER_MGMT'

const useDoGetContactsByLocation = ({
    onSuccess,
}: {
    onSuccess: (args:any)=>void,
}) => {
    const token = useJWTToken()

    return useMutation({
        mutationFn: ({
            selectedLocation,
        }: {selectedLocation: string | number}) => {
            const params = config.requestFn({
                includeFilters: {
                    locationId: [Number(selectedLocation)],
                    type: [ORDER_MGMT_TYPE],
                },
            })

            return simpleApi({
                ...params,
                token,
            })
        },
        onSuccess,
    })
}

export default useDoGetContactsByLocation
