import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    LaneGroupHandoverData,
} from '../../Pricing.types'
import requests from '../LaneGroup.requests'

export default ({
    onSuccess,
}: { onSuccess: (...args: any[]) => void }) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessCreateCompany,
        onError,
    } = useHandleStatusRequest({
        title: 'Lane Group was updated',
        id: 'LaneGroupUpdated',
        onSuccess,
    })

    const {
        mutate: updateHandoverData,
    } = useMutation({
        mutationFn: (laneGroupData: LaneGroupHandoverData) => {
            return simpleApi({
                ...requests.handoverData({
                    data: laneGroupData,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessCreateCompany,
    })

    return useCallback((laneGroupData: LaneGroupHandoverData) => {
        updateHandoverData(laneGroupData)
    }, [updateHandoverData])
}
