import {
    makeStyles,
} from 'app/tss'

const selection = 'selection'
const availableLanes = 'availableLanes'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateAreas: `
                "${selection}"
                "${availableLanes}"
            `,
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        availableLanes: {
            gridArea: availableLanes,
            minHeight: 300,
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
