import {
    LaneGroupTemperatureRange, LeaseType, OpportunityFrequency, OpportunityPalletType, ProductType,
} from 'app/types/enums'
import {
    DTOCardFormField, FieldInCard, NestedField,
} from 'app/types/form.types'
import {
    DeliveryServiceType, TransportModeRoad, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AccountLaneForm,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    App,
} from 'app/types/common.enums'
import {
    LaneGroup,
} from 'app/Apps/Pricing/Pricing.types'
import {
    RequestedLane,
    RequestedOpportunity,
} from '../../../AccountQuotes.types'
import {
    OpportunityType, Packaging,
} from '../../../../Opportunities/opportunities.types'

type AccountQuoteLaneGroupForm = keyof
    (LaneGroup & RequestedOpportunity & OpportunityType & RequestedLane & {
        airlineName: string,
    })

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
    isDeliveryRoad,
    laneGroupId,
    opportunityId,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL: boolean,
    showLTL: boolean,
    isDeliveryRoad: boolean,
    laneGroupId: number,
    opportunityId?: number,
}): DTOCardFormField <AccountQuoteLaneGroupForm>[] => {
    const generalFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'tempRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'LaneGroup.tempRange',
                    disabled: true,
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'LaneGroup.leaseType',
                    showInColumns: true,
                },
                {
                    name: 'changedOn',
                    componentName: 'DateTimeSelect',
                    labelKey: 'AccountLane.changedOn',
                    disabled: true,
                },
                {
                    name: 'changedBy',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.changedOn',
                    disabled: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'comment',
                    componentName: 'LongText',
                    labelKey: 'LaneGroup.comment',
                    disabled: true,
                },
                {
                    name: 'laneGroupNumber',
                    componentName: 'LinkTo',
                    link: `/apps/${App.PRICING}/lane-groups/all/edit/${laneGroupId}/overview`,
                    labelKey: 'LaneGroup.laneGroupNumber',
                },
            ],
        },
    ]

    const transportModeRoad = {
        name: 'transportModeRoad',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
    } as FieldInCard<AccountLaneForm>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        labelKey: 'LaneGroupCreateOrDuplicateDto.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<AccountLaneForm>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        labelKey: 'LaneGroupCreateOrDuplicateDto.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<AccountLaneForm>

    let transportModeFields = [transportModeRoad]

    if (showLTL) {
        transportModeFields = [
            ...transportModeFields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    if (showFTL) {
        transportModeFields = [
            ...transportModeFields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    const handoverFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'LaneGroup.deliveryServiceType',
                    required: true,
                },
                ...(isDeliveryRoad
                    ? transportModeFields : []
                ) as NestedField<AccountQuoteLaneGroupForm>[],
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'handoverPointLocationName',
                    componentName: 'LongText',
                    labelKey: 'LaneGroup.handoverPoint',
                },
                {
                    name: 'handoverPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneGroup.handoverPointContacts',
                    disabled: true,
                },
            ],
        },
    ]

    const collectionFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [{
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CollServiceType),
                labelKey: 'LaneGroup.collectionServiceType',
            }],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'collectionDropoffPointLocationName',
                    componentName: 'LongText',
                    labelKey: 'LaneGroup.collectionDropoffPoint',
                },
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'LaneGroup.collectionDropoffPointContacts',
                    disabled: true,
                },
            ],
        },
    ]

    const quoteDetailsFields: FieldInCard<AccountQuoteLaneGroupForm>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'opportunityNumber',
                    componentName: 'LinkTo',
                    labelKey: 'Opportunity.opportunityNumber',
                    link: `/apps/${App.ACCOUNT_MANAGEMENT}/opportunities/all/edit/${opportunityId}/overview`,
                },
                {
                    name: 'annualPalletVolume',
                    componentName: 'InputSingleline',
                    labelKey: 'Opportunity.annualPalletVolume',
                },
                {
                    name: 'currentPackagingSolution',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(Packaging),
                    labelKey: 'Opportunity.currentPackagingSolution',
                },
                {
                    name: 'validationNeeded',
                    componentName: 'Checkbox',
                    labelKey: 'Opportunity.validationNeeded',
                },
                {
                    name: 'airlineName',
                    componentName: 'InputSingleline',
                    labelKey: 'Opportunity.airline',
                },
                {
                    name: 'businessStart',
                    componentName: 'DateTimeSelect',
                    labelKey: 'Opportunity.businessStart',
                },
                {
                    name: 'opportunityComment',
                    componentName: 'LongText',
                    labelKey: 'Opportunity.opportunityComment',
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'competitorsPackaging',
                    componentName: 'EnumChipSelector',
                    allowedValues: getAllowedValues(Packaging),
                    labelKey: 'Opportunity.competitorsPackaging',
                },
                {
                    name: 'palletType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(OpportunityPalletType),
                    useDropdown: true,
                    labelKey: 'Opportunity.palletType',
                },
                {
                    name: 'productTypes',
                    componentName: 'EnumChipSelector',
                    allowedValues: getAllowedValues(ProductType),
                    labelKey: 'AccountQuotes.productTypes',
                },
                {
                    name: 'frequency',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(OpportunityFrequency),
                    useDropdown: true,
                    labelKey: 'Opportunity.frequency',
                },
                {
                    name: 'baseLeaseDays',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountQuotes.laneBaseLeaseDays',
                },
            ],
        },
    ]

    return [
        {
            id: 'general',
            title: 'General Information',
            className: 'general',
            fields: generalFields,
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: handoverFields,
        },
        {
            id: 'quoteDetails',
            title: 'Quote Details',
            className: 'quoteDetails',
            fields: quoteDetailsFields,
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: collectionFields,
        },
    ]
}

export default getFields
