import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import request from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateAirlineLease/createCustomerTransportForm.request'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    AutomaticQuoteRequestParams,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/CreateAirlineLease/createCustomerTransportForm.types'

const useCreateAutomaticQuote = ({
    onSuccess,
}: {
    onSuccess: (data: Lane) => void
}) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'AutomaticQuote',
        title: 'Automatic Quote',
    })

    return useMutation({
        mutationFn: (data: AutomaticQuoteRequestParams) => {
            return request.AutomaticQuote.mutationFn({
                data,
                token,
            })
        },
        onSuccess,
        onError,
    })
}

export default useCreateAutomaticQuote
