import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    OpportunityPalletType,
    ProductType,
} from 'app/types/enums'
import {
    OpportunityType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    RequestedOpportunity,
} from '../../AccountQuotes.types'

const columns = ({
    l,
}: {
    l: (l:string) => string,
    getAllowedValues: (e: Record<string, string>) => Record<string, string>
}): RichTableColumns<keyof (RequestedOpportunity & OpportunityType)>[] => {
    return [
        {
            id: 'opportunityNumber',
            headerProps: {
                children: l('Opportunity.opportunityNumber'),
            },
            mapCellProps: 'opportunityNumber',
        },
        {
            id: 'reprOriginAirport',
            headerProps: {
                children: l('AccountQuotes.opportunityFrom'),
            },
            mapCellProps: 'reprOriginAirport',
        },
        {
            id: 'reprDestinationAirport',
            headerProps: {
                children: l('AccountQuotes.opportunityTo'),
            },
            mapCellProps: 'reprDestinationAirport',
        },
        {
            id: 'temperatureRange',
            headerProps: {
                children: l('AccountQuotes.opportunityTempRange'),
            },
            mapCellProps: 'temperatureRange',
        },
        {
            id: 'productTypes',
            headerProps: {
                children: l('AccountQuotes.productTypes'),
            },
            mapCellProps: 'productTypes',
            componentName: 'EnumList',
            allowedValuesEnum: ProductType,
        },
        {
            id: 'palletType',
            headerProps: {
                children: l('Opportunity.palletType'),
            },
            mapCellProps: 'palletType',
            componentName: 'EnumValue',
            allowedValuesEnum: OpportunityPalletType,
        },
    ]
}

export default columns
