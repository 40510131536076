import {
    BillingCompany,
} from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanyForm.types'
import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import DTOSpec from 'app/services/dtoSpec'

import {
    getBillingCompanyOptionsLabel,
} from '../utils'

const billingCompanyConverter = (items) => {
    return items.map((billingCompany: BillingCompany) => {
        return {
            ...billingCompany,
            label: getBillingCompanyOptionsLabel(billingCompany),
        }
    })
}

export default (): (options: DelayedLoadOptions) => Promise<any> => {
    const loadOptions = useDelayedLoadOptions({
        url: DTOSpec.BillingCompany.Selector,
        params: {
            rows: 25,
            start: 0,
        },
        convertion: billingCompanyConverter,
    })

    return loadOptions
}
