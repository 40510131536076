export enum ActivityRecordType {
    MEETING = 'Meeting',
    CALL = 'Call'
}

export type ActivityRecordTable = {
    id: number,
    changedOn: string,
    companyName: string,
    organizer: string,
    participants: string[],
    subject: string,
    type: ActivityRecordType,
    happenedOn: string,
    durationInMinutes: number
}

export type ActivityRecord = {
    id: number,
    subject: string,
    type: ActivityRecordType,
    agenda: string,
    outcomes: string,
    durationInMinutes: number,
    happenedOn: string,
    company: { id: number },
    participants: { id: number }[],
    changedOn?: string,
    organizer: { id: number },
    archived: boolean,
}
