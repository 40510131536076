import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'

import requestConfig from '../../lane.request'

const useSetAsInactive = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessInactive,
        onError,
    } = useHandleStatusRequest({
        id: 'SetAsInactive',
        title: 'Set As Inactive',
        onSuccess,
    })

    return useMutation({
        mutationFn: (laneId: number) => {
            return simpleApi({
                ...requestConfig.SetAsInactive.requestFn({
                    id: laneId,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessInactive,
    })
}

export default useSetAsInactive
