import {
    useQuery,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import simpleApi from 'app/services/simpleApi'
import {
    TypeWithId,
} from 'app/Apps/Pricing/ContractBasis/utils/createQuote.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    LocationType,
} from 'app/types/enums'

import config from './AirportFilteredFromLaneGroupIds.config'

const useGetAirportsFromLaneGroupIds = (laneGroups: TypeWithId[]) => {
    const token = useJWTToken()

    const laneGroupIds = laneGroups?.map(({
        id,
    }) => {
        return id
    })

    const fetchAirports = () => {
        return simpleApi({
            ...config.AirportsLaneGroupsFiltered.requestFn(
                laneGroupIds,
            ),
            token,
        })
            .then((response) => {
                return response.map((airport) => {
                    return {
                        ...airport,
                        locationType: LocationType.AIRPORT,
                        label: airport.locationName,
                        value: airport.id,
                    }
                })
            })
    }

    const {
        data,
        isSuccess,
        isError,
        error,
    } = useQuery({
        queryKey: [
            RequestKeys.getAirportsFilteredByLaneGroupIds,
            laneGroupIds,
        ],
        queryFn: fetchAirports,
        enabled: laneGroupIds?.length > 0,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
        refetchInterval: false,
    })

    return {
        data,
        isSuccess,
        isError,
        error,
    }
}

export default useGetAirportsFromLaneGroupIds
