import React from 'react'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import tableConfig from './AccountQuotes.config'
import requests from './AccountQuotes.requests'
import AccountQuoteCreate from './AccountQuoteCreate'
import AssignQuote from './AssignQuote'
import AccountQuoteOverview from './AccountQuoteOverview'
import AccountQuotePdf from './AccountQuotePdf'
import {
    AccountQuote, QuoteStatus,
} from './AccountQuotes.types'
import LanesToBeGrouped from './LanesToBeGrouped'
import AssignPricing from './AssignPricing'
import LanesToAwardReject from './LanesToAwardReject'
import AccountQuoteUpdate from './AccountQuoteUpdate'

const quoteStatusCondition = ({
    quoteStatus,
    linkedContractBasis,
}: AccountQuote = {} as AccountQuote) => {
    return Boolean(quoteStatus)
        && !(quoteStatus === QuoteStatus.CREATED
            || (quoteStatus === QuoteStatus.CANCELLED && !(linkedContractBasis?.id)))
}

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: AccountQuoteOverview,
}

const assignToContractBasisTab: SidebarTab = {
    label: 'Assign Quote',
    url: 'assign-quote',
    Component: AssignQuote,
}

const dataUpdateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data-update',
    Component: AccountQuoteUpdate,
}

const reviewGroupTab: SidebarTab = {
    label: 'Review/Group',
    url: 'review-group',
    Component: LanesToBeGrouped,
    condition: quoteStatusCondition,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('review-group')
    },
}

const assignPricingTab: SidebarTab = {
    label: 'Assign Pricing',
    url: 'assign-pricing',
    Component: AssignPricing,
    condition: quoteStatusCondition,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('assign-pricing')
    },
}

const awardRejectTab: SidebarTab = {
    label: 'Award/Reject',
    url: 'award-reject',
    Component: LanesToAwardReject,
    activeComparer: (pathname) => {
        return pathname.split('/').includes('award-reject')
    },
}

const quotePdfTab: SidebarTab = {
    label: 'Quote PDF',
    url: 'quote-pdf',
    Component: AccountQuotePdf,
    condition: quoteStatusCondition,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const defaultProps = {
    isCreateFormEnabled: true,
}

const AccountQuotes = ({
    isCreateFormEnabled,
}: {
    isCreateFormEnabled?: boolean,
}) => {
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])
    const accountQuoteLaneGroup = useHasPermission(['account_quote_lane_group'])
    const accountQuoteLaneAwardReject = useHasPermission(['account_quote_lane_award_reject'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: dataUpdateTab,
            permission: accountUpdate,
        },
        {
            tab: assignToContractBasisTab,
            permission: accountQuoteLaneGroup,
        },
        {
            tab: reviewGroupTab,
            permission: accountQuoteLaneGroup,
        },
        {
            tab: assignPricingTab,
            permission: accountQuoteLaneGroup,
        },
        {
            tab: awardRejectTab,
            permission: accountQuoteLaneAwardReject,
        },
        {
            tab: quotePdfTab,
            permission: true,
        },
    ])

    return (
        <SkyNetTable
            createForm={isCreateFormEnabled && accountCreate ? AccountQuoteCreate : null}
            name={tableConfig.name}
            moduleName={requests.domainName}
            getDomainObject={requests.get}
            domainRequestKey={RequestKeys.getAccountQuote}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
        />
    )
}

AccountQuotes.defaultProps = defaultProps

export default AccountQuotes
