import React, {
    useMemo, useCallback,
} from 'react'
import {
    user,
} from '@skycell-ag/auth'

import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    countFurtherMonths, parseForecastData, createDefaultValues,
} from '../Forecast.utils'
import getTableConfig from './ShortTermForecasts.config'
import requests from '../Forecast.requests'
import {
    ForecastOngoingOpportunities, ForecastType,
} from '../Forecast.types'
import ShortTermForecastOverview from './ShortTermForecastOverview'
import ShortTermForecastUpdate from './ShortTemForecastUpdate'
import useHasPermission from '../../../hooks/useHasPermission'
import CurrentMonthForecastUpdate from './CurrentMonthForecastUpdate'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: ShortTermForecastOverview,
}

const ShortTermForecasts = () => {
    const superUpdatePermission = useHasPermission(['forecast_super'])
    const updateRelatedPermission = useHasPermission(['forecast_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: 'data-update',
                Component: ShortTermForecastUpdate,
                condition: (item: ForecastType) => {
                    return superUpdatePermission
                        || item.responsibleUsers?.includes(user?.data?.email)
                },
            },
            permission: superUpdatePermission || updateRelatedPermission,
        },
        {
            tab: {
                label: 'Current Month Update',
                url: 'current-month-update',
                Component: CurrentMonthForecastUpdate,
            },
            permission: superUpdatePermission,
        },
    ])

    const forecastFields = useMemo(() => {
        return countFurtherMonths()
    }, [])

    const tableConfig = useMemo(() => {
        return getTableConfig({
            tmpFields: Array.from(forecastFields).sort().reverse().map(([
                key,
                value,
            ]) => {
                return {
                    name: key,
                    headerProps: {
                        children: value,
                    },
                }
            }),
        })
    }, [forecastFields])

    const parseResponse = useCallback((response: TableResponse<ForecastOngoingOpportunities>) => {
        return {
            ...response,
            data: (
                (response?.data || []) as ForecastOngoingOpportunities[]
            ).reduce((acc, item) => {
                return [
                    ...acc,
                    {
                        ...item,
                        ...(item.forecasts || []).reduce(
                            parseForecastData(), createDefaultValues(forecastFields),
                        ),
                    },
                ]
            }, []),
        }
    }, [forecastFields])

    return (
        <SkyNetTable
            customUrl={requests.getForecastOngoingOpportunities().url}
            name={tableConfig.name}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            parseResponse={parseResponse}
            getDomainObject={requests.get}
            domainRequestKey={RequestKeys.getForecastById}
            showSearch
        />
    )
}

export default ShortTermForecasts
