import React, {
    useMemo,
} from 'react'

import Typeahead from 'app/shared-components/Typeahead'

import useBillingCompanyRequests from './hooks/useBillingCompanyRequests'
import useBillingCompanySearch from './hooks/useBillingCompanySearch'
import {
    getBillingCompanyLabel,
    getBillingCompanyTooltip,
} from './utils'
import {
    BillingCompanySelectorDefaultProps,
    BillingCompanySelectorProps,
} from './types/BillingCompanySelector.types'

import useStyles from './BillingCompanySelector.styles'

const BillingCompanySelector = ({
    name,
    title,
    value,
    onChange,
    className,
    classNames,
    isRequired,
    'data-testid': dataTestId,
    disabled,
} : BillingCompanySelectorProps) => {
    const {
        classes,
    } = useStyles()

    const {
        billingCompany,
        labelData,
    } = useBillingCompanyRequests(value, getBillingCompanyLabel)

    const tooltipValue = useMemo((): JSX.Element => {
        return getBillingCompanyTooltip(billingCompany)
    }, [billingCompany])

    const loadOptions = useBillingCompanySearch()

    return (
        <div
            className={className}
            data-testid={dataTestId}
        >
            <Typeahead
                name={name}
                value={value}
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={labelData}
                labelField="label"
                classNames={{
                    options: classes.options,
                    ...classNames,
                }}
                className={className}
                disabled={disabled}
                isRequired={isRequired}
                labelTooltip={tooltipValue}
            />
        </div>
    )
}

BillingCompanySelector.defaultProps = BillingCompanySelectorDefaultProps

export default BillingCompanySelector
