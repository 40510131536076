import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'

const useSendToAbacus = ({
    onSuccess,
    onError,
}: {
    onSuccess: () => void,
    onError: () => void,
}) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRequest,
        onError: onErrorRequest,
    } = useHandleStatusRequest({
        id: 'SendToAbacus',
        title: 'Send To Abacus',
        onSuccess,
        onError,
    })

    return useMutation({
        mutationFn: ({
            selectedInvoiceId,
        }: {
            selectedInvoiceId: number,
        }) => {
            return orderManagementRequest.Invoices.SendToAbacus.mutationFn({
                token,
                selectedInvoiceId,
            })
        },
        onSuccess: onSuccessRequest,
        onError: onErrorRequest,
    })
}

export default useSendToAbacus
