import {
    Currency,
    OrderStatuses,
} from 'app/types/enums'
import {
    CancellationReasons,
    SharedPositioningType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

const getSuffix = (days: number) => {
    if (!days) {
        return undefined
    }

    return days > 1 ? ' Days' : ' Day'
}

type Args = {
    baseLeaseDays: number,
    billingCurrency: Currency,
    orderNumber: string,
    extraDays: number,
    isCancelled: boolean,
    sharedPositioning: SharedPositioningType[],
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
}

const getFields = ({
    baseLeaseDays,
    billingCurrency,
    extraDays,
    isCancelled,
    getAllowedValues,
    orderNumber,
    sharedPositioning,
}: Args) => {
    const cancellationCard = {
        id: 'Cancellation',
        className: 'cancellation',
        title: 'Cancellation Info',
        fields: [
            {
                name: 'cancellationDate',
                componentName: 'DateTimeZonedSelect',
                disabled: true,
                labelKey: 'OrderBillingInformationView.cancellationDate',
            },
            {
                name: 'cancellationReason',
                componentName: 'EnumSelector',
                disabled: true,
                allowedValues: getAllowedValues(CancellationReasons),
                labelKey: 'OrderBillingInformationView.cancellationReason',
            },
            {
                name: 'cancellationComment',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'OrderBillingInformationView.cancellationComment',
            },
        ],
    }
    const contactInformationCard = {
        id: 'contactInformation',
        title: 'Contact Information',
        className: 'contactInformation',
        fields: [
            {
                name: 'billingCompany',
                componentName: 'BillingCompanyExpandedViewSelector',
                labelKey: 'OrderBillingView.billingCompany',
            },
            {
                name: 'billingCompanyRemarks',
                componentName: 'LongText',
                disabled: true,
                labelKey: 'OrderBillingView.billingRemarks',
            },
            {
                name: 'customer',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderBillingInformationView.customer',
                disabled: true,
            },
        ],
    }
    const billingInformationCard = {
        id: 'billingInformation',
        title: 'Billing Information',
        className: 'billingInformation',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'leaseStartTimestamp',
                        componentName: 'DateTimeZonedSelect',
                        disabled: true,
                        labelKey: 'OrderBilling.leaseStartTimestamp',
                    },
                    {
                        name: 'billing.leaseEndTimestamp',
                        componentName: 'InputSingleline',
                        disabled: true,
                        labelKey: 'OrderBilling.leaseEndTimestamp',
                    },
                    {
                        name: 'billing.billingCompany.hasBulkInvoice',
                        componentName: 'Checkbox',
                        labelKey: 'BillingCompanyDto.hasBulkInvoice',
                        disabled: true,
                    },
                    {
                        name: 'hasBillingCompleted',
                        componentName: 'Checkbox',
                        labelKey: 'OrderBilling.hasBillingCompleted',
                    },
                    {
                        name: 'requiresManualBillingReviewPricing',
                        componentName: 'Checkbox',
                        labelKey: 'OrderBilling.requiresManualBillingReviewPricing',
                    },
                    {
                        name: 'requiresManualBillingReviewOps',
                        componentName: 'Checkbox',
                        labelKey: 'OrderByNumberView.requiresManualBillingReviewOps',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'billingCurrency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Currency),
                        labelKey: 'OrderBillingView.billingCurrency',
                    },
                    {
                        name: 'billing.baseLeaseDays',
                        componentName: 'InputSingleline',
                        type: 'number',
                        disabled: true,
                        suffix: getSuffix(baseLeaseDays),
                        labelKey: 'OrderBilling.baseLeaseDays',
                    },
                    {
                        name: 'billing.extraDays',
                        componentName: 'InputSingleline',
                        type: 'number',
                        disabled: true,
                        suffix: getSuffix(extraDays),
                        labelKey: 'OrderBilling.extraDays',
                    },
                    {
                        name: 'sharedPositioning',
                        componentName: 'SharePositioningSelect',
                        value: sharedPositioning,
                        orderNumber,
                        disabled: true,
                        labelKey: 'Order.sharesPositioning',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'billingComment',
                        componentName: 'LongText',
                        labelKey: 'OrderBilling.billingComment',
                    },
                    ...billingCurrency ? [{
                        name: 'billing.billedAmount',
                        componentName: 'CurrencyHeadingSelector',
                        disabled: true,
                        currency: billingCurrency,
                        labelKey: 'OrderBillingView.billedAmount',
                    }] : [],
                ],
            },
        ],
    }
    const orderSummaryCard = {
        id: 'orderSummary',
        title: 'Order Summary',
        className: 'orderSummary',
        fields: [
            {
                name: 'orderNumber',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'OrderBillingInformationView.orderNumber',
            },
            {
                name: 'laneNumber',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'OrderBillingInformationView.laneNumber',
            },
            {
                name: 'tempRange',
                componentName: 'InputSingleline',
                disabled: true,
            },
            {
                name: 'account.companyName',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'RegionalGroups.accountName',
            },
            {
                name: 'orderStatus',
                componentName: 'EnumSelector',
                disabled: true,
                allowedValues: getAllowedValues(OrderStatuses),
                labelKey: 'OrderBillingInformationView.orderStatus',
            },
            {
                name: 'personInCharge',
                componentName: 'InputSingleline',
                disabled: true,
                labelKey: 'OrderBillingInformationView.personInCharge',
            },
        ],
    }
    const containersCard = {
        id: 'containers',
        title: 'Containers',
        className: 'containers',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'selectedContainers',
            componentName: 'BillingInformationContainers',
        }],
    }

    return isCancelled ? [
        contactInformationCard,
        billingInformationCard,
        orderSummaryCard,
        cancellationCard,
        containersCard,
    ] : [
        contactInformationCard,
        billingInformationCard,
        orderSummaryCard,
        containersCard,
    ]
}

export default getFields
