import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    AttitudeToSkyCell, EngagementTableType,
} from './engagement.types'

export const PROSPECT_CONTACTS_URL = 'prospect'

const config: SkyNetTableConfig<keyof EngagementTableType> = {
    name: 'EngagementTable',
    tabs: [
        {
            name: 'Prospect Contacts',
            url: PROSPECT_CONTACTS_URL,
            requestUrl: 'prospect',
        },
        {
            name: 'Contacts',
            url: 'all',
            includeFilters: {
                contactIsProspect: [false],
            },
        },
    ],
    fields: [
        {
            name: 'contactName',
            reduced: true,
            labelKey: 'Engagement.contactName',
        },
        {
            name: 'contactCompanyName',
            filterField: 'contactCompanyName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'Engagement.contactCompanyName',
        },
        {
            name: 'contactCountryName',
            filterField: 'contactCountryName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'Engagement.contactCountryName',
        },
        {
            name: 'attitudeToSkyCell',
            filterField: 'attitudeToSkyCell',
            filterType: FilterType.Array,
            componentName: 'AttitudeToSkyCellTable',
            allowedValuesEnum: AttitudeToSkyCell,
            labelKey: 'Engagement.attitudeToSkyCell',
        },
    ],
}

export default config
