import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import config from 'app/Apps/OrderManagement/Orders/Components/OrderNotifications/utils/OrderNotifications.config'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

export enum Count {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
}

type RequestParams = {
    orderNumber: string,
    notificationId: number,
    count: Count,
}

const useTriggerNotifications = (
    onSuccess: () => void,
    onError: () => void,
): UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessTrigger,
        onError: onErrorTrigger,
    } = useHandleStatusRequest({
        id: 'Notification',
        title: 'Notification updated',
        onSuccess,
        onError,
    })

    return useMutation({
        mutationFn: ({
            orderNumber,
            notificationId,
            count,
        }: RequestParams) => {
            return config.TriggerNotifications.mutationFn({
                orderNumber,
                notificationId,
                count,
                token,
            })
        },
        onSuccess: onSuccessTrigger,
        onError: onErrorTrigger,
    })
}

export default useTriggerNotifications
