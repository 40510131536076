import {
    useEffect,
} from 'react'
import {
    useFilterContext,
} from 'app/hooks/useFilter'

// sets data when filter is applied
export default ({
    emptyRows,
    createEmptyDataRow,
    setData,
    discardInitValue,
}: {
    emptyRows: number,
    createEmptyDataRow: () => Record<string, any>,
    setData: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
    discardInitValue: () => void
}) => {
    const filterContext = useFilterContext()

    useEffect(() => {
        setData((prev) => {
            let result

            if (filterContext?.filteredData) {
                discardInitValue()
                result = filterContext.filteredData

                if (emptyRows && emptyRows > result.length) {
                    result = [
                        ...result,
                        ...Array.from(Array(emptyRows - result.length), () => {
                            return createEmptyDataRow()
                        }),
                    ]
                }

                return result
            }
            return prev
        })
    }, [
        emptyRows,
        createEmptyDataRow,
        filterContext?.filteredData,
        filterContext?.filter,
        setData,
        discardInitValue,
    ])
}
