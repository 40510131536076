import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        gridCardWrapper: {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateColumns: '1fr',

        },
        general: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        type: {
            gridColumnStart: 1,
            gridColumnEnd: 4,
        },
        company: {
            gridColumnStart: 1,
        },
        participants: {
            gridColumnStart: 1,
        },
        changedOn: {
            gridColumnStart: 1,
        },
    }
})

export default useStyles
