import {
    FieldInCard,
} from 'app/types/form.types'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    LoggerResponse, LoggerStatus,
} from '../Loggers.types'

type LoggerOverviewFields = LoggerResponse & {
    'gateway.gatewayNumber': string,
    'gateway.locationRemark': string,
    'gateway.location.id': string,
    'gateway.areaName': string,
}

const loggerFields = ({
    containerSerialNumber,
    getAllowedValues,
}): FieldInCard<keyof LoggerOverviewFields>[] => {
    return [
        {
            id: 'loggers',
            title: 'Loggers',
            className: 'loggers',
            fields: [
                {
                    name: 'loggerNumber',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'containerSerialNumber',
                    componentName: 'SkyNetLink',
                    link: `/apps/containers/containers/all/edit/${containerSerialNumber}/overview`,
                    title: 'Container Serial Number',
                    text: containerSerialNumber,
                    type: TypeLink.INTERNAL_WITH_ICON,
                    size: SizeLink.S,
                },
                {
                    name: 'batteryVoltage',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'loggerTransmissionRateSeconds',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'loggerStatus',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LoggerStatus),
                },
                {
                    name: 'loggerQrCode',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'loggerType',
                    labelKey: 'LoggerView.loggerType',
                    componentName: 'LoggerTypeSelector',
                },
            ],
        },
        {
            id: 'gateway',
            title: 'Last Connected Gateway',
            name: 'gateway',
            className: 'gateway',
            labelKey: 'Logger.gateway',
            fields: [
                {
                    name: 'gateway.gatewayNumber',
                    labelKey: 'Logger.gateway',
                    componentName: 'InputSingleline',
                },
                {
                    name: 'gateway.location.id',
                    labelKey: 'Gateway.location',
                    componentName: 'LocationSelector',
                },
                {
                    name: 'gateway.areaName',
                    componentName: 'InputSingleline',
                    labelKey: 'Gateway.areaName',
                },
                {
                    name: 'gateway.locationRemark',
                    componentName: 'LongText',
                    labelKey: 'Gateway.locationRemark',
                },
            ],
        },

    ]
}

export default loggerFields
