import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    Location,
} from '../Locations.types'
import locationRequests from '../Locations.request'

export default ({
    onSuccess,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessCreateLocation,
        onError,
    } = useHandleStatusRequest({
        title: 'New location was created',
        id: 'LocationCreated',
        onSuccess,
    })

    const {
        mutate: createLocation,
    } = useMutation({
        mutationFn: (location: Location) => {
            return simpleApi({
                ...locationRequests.create({
                    data: location,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessCreateLocation,
    })

    return useCallback((location: Location) => {
        createLocation(location)
    }, [createLocation])
}
