import React, {
    useState, useMemo, useCallback,
} from 'react'
import isEqual from 'lodash/isEqual'

import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'
import SkyNetSpreadSheet, {
    SkyNetSpreadSheetControlPanel,
} from 'app/shared-components/SkyNetSpreadSheet'

import {
    FilterContext,
    FilterContextType,
    FilterOptions,
    FilteredData,
} from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import getConfig from './ForecastSpreadsheet.config'
import {
    ForecastDataSpreadsheetColumnsConfig,
    ForecastSpreadsheetDataType,
} from '../Forecast.types'

import foreCastFilterConfig from './ForecastSpreadsheet.filterConfig'
import requests from '../Forecast.requests'

const defaultProps = {
    onSave: undefined,
    filterOptions: undefined,
}

const ForecastSpreadsheet = ({
    onSave,
    tmpFields,
    filterOptions,
}: {
    onSave?: (i: ForecastSpreadsheetDataType[], o?: AsyncMutationOptions) => void,
    tmpFields: ForecastDataSpreadsheetColumnsConfig,
    filterOptions?: FilterOptions
}) => {
    const [
        value,
        setValue,
    ] = useState(filterOptions?.filteredData as FilteredData<ForecastSpreadsheetDataType[]>)

    const getLabel = useLabel()
    const getAllowedValues = useEnumValues()

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            getAllowedValues,
            tmpFields,
        })
    }, [
        getLabel,
        getAllowedValues,
        tmpFields,
    ])

    const handleSave = useCallback(async (options?: AsyncMutationOptions) => {
        return onSave(value, options)
    }, [
        value,
        onSave,
    ])

    const saveBeforeFilter = useCallback((setFilter) => {
        handleSave({
            onSuccess: setFilter,
        })
    }, [handleSave])

    const edited = !isEqual(filterOptions?.filteredData, value)

    const filterConfig: FilterContextType = useMemo(() => {
        return {
            ...filterOptions,
            config: foreCastFilterConfig,
            request: requests.filterCount,
            beforeFilterCallback: saveBeforeFilter,
            edited,
        }
    }, [
        edited,
        filterOptions,
        saveBeforeFilter,
    ])

    return (
        <FilterContext.Provider value={filterConfig}>
            <SkyNetSpreadSheetControlPanel
                onSave={handleSave}
                edited={edited}
            >
                <SkyNetSpreadSheet
                    config={config}
                    value={value}
                    disabled={false}
                    onChange={setValue}
                />
            </SkyNetSpreadSheetControlPanel>
        </FilterContext.Provider>
    )
}

ForecastSpreadsheet.defaultProps = defaultProps

export default ForecastSpreadsheet
