import React, {
    useMemo,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import userRequests from 'app/Apps/ContactManagement/Users/Users.request'
import {
    userParser,
} from 'app/shared-components/UserSelector/UserSelector'
import useGetUsers from 'app/Apps/ContactManagement/Users/hooks/useGetUsers'

type Props = {
    value?: {
        id: number,
    }[],
    onChange: (newValue: number[]) => void,
    title?: string,
    disabled?: boolean,
    required?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'Users',
    disabled: false,
    required: false,
}

const MultipleUserSelector = ({
    value,
    onChange,
    title,
    disabled,
    required,
}: Props) => {
    const loadOptions = useDelayedLoadOptions({
        url: userRequests.all.url, convertion: userParser,
    })

    const userIds = useMemo(() => {
        return value.map(({
            id,
        }) => {
            return id
        })
    }, [value])

    const {
        data: users,
    } = useGetUsers(userIds)

    const enhancedValue = useMemo(() => {
        if (!userIds?.length) {
            return []
        }

        return users?.map(({
            id, userName,
        }) => {
            return {
                label: userName,
                value: id,
                id,
            }
        })
    }, [
        userIds?.length,
        users,
    ])

    return (
        <ChipSelect
            title={title}
            value={enhancedValue}
            onChange={onChange}
            loadOptions={loadOptions}
            showTitleOptions
            disabled={disabled}
            required={required}
        />
    )
}

MultipleUserSelector.defaultProps = defaultProps

export default MultipleUserSelector
