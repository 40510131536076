import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    RequestedLane,
    RequestedOpportunity,
} from '../../AccountQuotes.types'
import {
    AccountLanesTable,
} from '../../../AccountLanes/AccountLanes.types'

const columns = ({
    l,
}: {
    l: (l: string) => string,
}): RichTableColumns<keyof (RequestedLane & AccountLanesTable
    & Pick<RequestedOpportunity, 'productTypes'>)>[] => {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: l('AccountQuotes.lanes'),
            },
            mapCellProps: 'laneNumber',
        },
        {
            id: 'originAirport',
            headerProps: {
                children: l('AccountQuotes.laneFrom'),
            },
            mapCellProps: 'originAirport',
        },
        {
            id: 'destinationAirport',
            headerProps: {
                children: l('AccountQuotes.laneTo'),
            },
            mapCellProps: 'destinationAirport',
        },
        {
            id: 'deliveryServiceType',
            headerProps: {
                children: l('AccountQuotes.laneDeliveryType'),
            },
            mapCellProps: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            id: 'collectionServiceType',
            headerProps: {
                children: l('AccountQuotes.laneCollectionType'),
            },
            mapCellProps: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollServiceType,
        },
        {
            id: 'productTypes',
            componentName: 'List',
            headerProps: {
                children: l('AccountQuotes.productTypes'),
            },
            mapCellProps: 'productTypes',
        },
        {
            id: 'baseLeaseDays',
            headerProps: {
                children: l('AccountQuotes.laneBaseLeaseDays'),
            },
            mapCellProps: ({
                baseLeaseDays,
            }) => {
                return {
                    children: baseLeaseDays.join(', '),
                }
            },
        },
        {
            id: 'otherComments',
            headerProps: {
                children: l('AccountQuotes.quoteLaneComment'),
            },
            mapCellProps: 'otherComments',
        },
    ]
}

export default columns
