import React, {
    useCallback,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    useHistory,
} from 'react-router-dom'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import ordersToBeConfirmedConfig from './ordersToBeConfirmed.config'
import bookingsWaitingForPricing from './bookingsWaitingForPricing.config'
import requests from './openBookings.requests'
import orderManagementRequest from '../services/orderManagement.request'
import {
    OrdersToBeConfirmedType,
} from './openBookings.types'
import useStyles from './OpenBookings.style'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]
const OpenBookings = () => {
    const {
        classes,
    } = useStyles()
    const history = useHistory()

    const onRowClickToBeConfirmed = useCallback(({
        orderNumber,
    }: OrdersToBeConfirmedType): void => {
        history.push(orderManagementRequest.OpenBookings.moveToUrl(orderNumber))
    }, [history])

    const onRowClickWaitingForPricing = useCallback(({
        id,
    }: OrdersToBeConfirmedType): void => {
        history.push(orderManagementRequest.PreBookings.moveToUrl(id))
    }, [history])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Orders To Be Confirmed
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={ordersToBeConfirmedConfig.name}
                    onRowClick={onRowClickToBeConfirmed}
                    tableControls={tableControls}
                    tableConfig={ordersToBeConfirmedConfig}
                    customUrl={requests.OrdersToBeConfirmed().url}
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>
                Bookings Waiting For Pricing
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={bookingsWaitingForPricing.name}
                    onRowClick={onRowClickWaitingForPricing}
                    tableControls={tableControls}
                    tableConfig={bookingsWaitingForPricing}
                    customUrl={requests.BookingsWaitingForPricing().url}
                    showSearch
                />
            </div>
        </div>
    )
}

export default OpenBookings
