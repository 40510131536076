import {
    useCallback, useEffect,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import {
    PreBookingEntity,
} from '../../PreBooking.types'
import requests from '../../PreBookings.requests'

const usePreBookingCreate = ({
    onSuccess,
    setIsEdited,
    setIsLoading,
}) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessPreBookingCreate,
        onError,
    } = useHandleStatusRequest({
        title: 'New Pre booking was created',
        id: 'PreBookingCreated',
        onSuccess,
    })

    const {
        isPending: isLoading,
        mutate: createPreBooking,
    } = useMutation({
        mutationFn: (prebooking: PreBookingEntity) => {
            return simpleApi({
                ...requests.create({
                    data: prebooking,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessPreBookingCreate,
    })

    useEffect(() => {
        setIsLoading(isLoading)
    }, [
        isLoading,
        setIsLoading,
    ])

    return useCallback(async (prebooking: PreBookingEntity) => {
        setIsEdited(false)
        return createPreBooking(prebooking)
    }, [
        createPreBooking,
        setIsEdited,
    ])
}

export default usePreBookingCreate
