import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        options: {
            width: 450,
        },
        chip: {
            margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
            backgroundColor: theme.palette.secondary[200],
            color: theme.palette.common.black,
        },
        companyIcon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
    }
})

export default useStyles
