import React from 'react'
import {
    BillingCompany,
} from 'app/Apps/ContactManagement/BillingCompanies/BillingCompanyForm.types'

const getBillingCompanyData = ({
    street,
    city,
    zip,
}) => {
    return [
        street,
        city,
        zip,
    ].filter(Boolean)
}

const getBillingCompanyOptionsLabel = ({
    billingLocationName,
    street,
    city,
    countryCountryName,
}: BillingCompany) => {
    return `${billingLocationName}, ${street} ${city}, ${countryCountryName}`
}

const getBillingCompanyLabel = ({
    billingLocationName,
    city,
}: BillingCompany) => {
    return {
        label: `${billingLocationName}, ${city}`,
    }
}

const getBillingCompanyExpandedLabel = (billingCompany: BillingCompany) => {
    const {
        billingLocationName,
    } = billingCompany

    return {
        label: `${billingLocationName}, ${getBillingCompanyData(billingCompany).join('\n')}`,
    }
}

const getBillingCompanyTooltip = (billingCompany: BillingCompany) => {
    if (!billingCompany) {
        return null
    }

    const {
        id,
        billingLocationName,
    } = billingCompany

    return (
        <>
            {[
                billingLocationName,
                ...getBillingCompanyData(billingCompany),
            ].map((el) => {
                return (<div key={`billingCompany${id}-${el}`}>{el}</div>)
            })}
        </>
    )
}

export {
    getBillingCompanyLabel,
    getBillingCompanyExpandedLabel,
    getBillingCompanyOptionsLabel,
    getBillingCompanyTooltip,
}
