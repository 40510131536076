import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import config from '../QuotePdfDescriptionEdit.request'

type RequestParams = {
    quoteId: number,
    description: string,
    documentId: number,
}

const useQuotePdfDescriptionEdit = (
    onSuccess: () => void,
): UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRemoveInvoice,
        onError,
    } = useHandleStatusRequest({
        id: 'QuotePdf',
        title: 'Document Description has been updated',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            quoteId,
            description,
            documentId,
        }: RequestParams) => {
            return config.UpdatePdfQuoteDescription.mutationFn({
                quoteId,
                description,
                documentId,
                token,
            })
        },
        onSuccess: onSuccessRemoveInvoice,
        onError,
    })
}

export default useQuotePdfDescriptionEdit
