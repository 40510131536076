import React, {
    useMemo,
} from 'react'

import Button from 'app/shared-components/Button'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import validateForm from 'app/utils/validateForm'
import getFields from './finalizeBookingStep.fields'
import useStyles from './FinalizeBookingStep.style'
import {
    FinalizeBookingStepType,
} from '../../createCustomerTransportForm.types'

const FinalizeBookingStep = ({
    setActiveStep,
    onChange,
    value,
    index,
    laneData,
    onReset,
}: {
    value: FinalizeBookingStepType,
    onChange: (val: Partial<FinalizeBookingStepType>) => void,
    setActiveStep: (val: number) => void,
    onReset: () => void,
    index: number,
    laneData: Lane,
}) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const {
        originScSelection,
        originScBackup,
        fwdPosBackup,
    } = value

    const isDeliveryRoad = laneData.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD

    const fields = useFieldsWithClassName(getFields({
        originScSelection,
        isDeliveryRoad,
        laneData,
        getAllowedValues,
        originScBackup,
        fwdPosBackup,
    }), classes)

    const onClickComplete = () => {
        setActiveStep(index + 1)
    }

    const onClickBack = () => {
        setActiveStep(index - 1)
        onReset()
    }

    const isFormValid: boolean = useMemo(() => {
        return validateForm({
            fields,
            value,
        })
    }, [
        fields,
        value,
    ])

    return (
        <div>
            <DomainObjectForm
                value={value}
                onChange={onChange}
                fields={fields}
                name="OrderByNumberView"
            />
            <div className={classes.actions}>
                <Button
                    label="Continue"
                    onClick={onClickComplete}
                    className={classes.button}
                    disabled={!isFormValid}
                    data-testid="btn-complete"
                />
                <Button
                    label="Back"
                    secondary
                    onClick={onClickBack}
                    className={classes.button}
                    data-testid="btn-back"
                />
            </div>
        </div>
    )
}

export default FinalizeBookingStep
