import {
    useUserRoles,
} from '@skycell-ag/auth'

import hasPermission from 'app/utils/hasPermission'

/**
* Use this to get a list of users permissions
* @param    {Array}  permissions array to check
* @return   {Array}  array list of permissions
*/

const useGetPermissions = (permissions) => {
    const roles = useUserRoles()

    return permissions.map((permission) => {
        const has = hasPermission([permission], roles)

        return has ? permission : null
    }).filter(Boolean)
}

export default useGetPermissions
