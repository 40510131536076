import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import config from '../../invoce.request'

type RequestParams = {
    orderNumber: string,
    data: {id: number},
}

const useRelinkLaneFee = (onSuccess: () => void)
    : UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRelinkLaneFee,
        onError,
    } = useHandleStatusRequest({
        id: 'relinkLaneFee',
        title: 'Relink Lane Fee',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            orderNumber,
            data,
        }: RequestParams) => {
            return config.RelinkLaneFee.mutationFn({
                orderNumber,
                data,
                token,
            })
        },
        onSuccess: onSuccessRelinkLaneFee,
        onError,
    })
}

export default useRelinkLaneFee
