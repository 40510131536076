import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    LaneStatus,
} from 'app/types/enums'
import requests from 'app/Apps/Pricing/LaneGroups/Lanes/Lane.requests'

export default ({
    onSuccess,
}) => {
    const token = useJWTToken()

    const {
        onSuccess: onSuccessLaneStatusUpdate,
        onError,
    } = useHandleStatusRequest({
        title: 'Lanes Statuses were updated',
        id: 'laneStatuses',
        onSuccess,
    })

    const {
        mutate: updateLanesStatuses,
    } = useMutation({
        mutationFn: ({
            laneStatus, lanes,
        }: {
            laneStatus: LaneStatus,
            lanes: {id: number}[]
        }) => {
            return simpleApi({
                ...requests.status({
                    laneStatus, lanes,
                }),
                token,
            })
        },
        onError,
        onSuccess: onSuccessLaneStatusUpdate,
    })

    return useCallback(({
        laneStatus, lanes,
    }: {
        laneStatus: LaneStatus,
        lanes: {id: number}[]
    }) => {
        updateLanesStatuses({
            laneStatus, lanes,
        })
    }, [updateLanesStatuses])
}
