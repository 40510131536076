import {
    useMutation,
    UseMutationResult,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import config from 'app/Apps/OrderManagement/Orders/CustomerTransport/Invoices/invoce.request'
import {
    OutgoingInvoice,
} from 'app/Apps/OrderManagement/Orders/orders.types'

type RequestParams = {
    orderNumber: string,
    invoice: OutgoingInvoice,
}

const useRemoveInvoice = (
    onSuccess: (params: OutgoingInvoice) => void,
): UseMutationResult<unknown, unknown, RequestParams> => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessRemoveInvoice,
        onError,
    } = useHandleStatusRequest({
        id: 'OutgoingInvoice',
        title: 'Remove OutgoingInvoice',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            orderNumber,
            invoice,
        }: RequestParams) => {
            return config.RemoveInvoice.mutationFn({
                orderNumber,
                invoice,
                token,
            })
        },
        onSuccess: onSuccessRemoveInvoice,
        onError,
    })
}

export default useRemoveInvoice
