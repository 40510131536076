import {
    useContext, createContext,
} from 'react'

export type AccountQuoteContextType = {
    quoteId: number,
}

export const AccountQuoteContext = createContext<AccountQuoteContextType>(null)

const useAccountQuoteContext = () => {
    return useContext<AccountQuoteContextType>(AccountQuoteContext)
}

export default useAccountQuoteContext
