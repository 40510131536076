import React, {
    useMemo,
} from 'react'
import {
    useUserRoles,
} from '@skycell-ag/auth'

import useSelectedTab from 'app/MainMenu/Panel/ActiveTab/hooks/useSelectedTab'
import mainMenuConfig from 'app/mainMenuConfig'

import Menu from './Menu'

const MenuContainer = () => {
    const {
        id: selectedId,
    } = useSelectedTab()

    const roles = useUserRoles()

    const allowedItems = useMemo(() => {
        return mainMenuConfig
            .reduce((acum, item) => {
                return [
                    ...acum,
                    {
                        ...item,
                        links: item.links.filter(({
                            auth,
                        }) => {
                            if (!auth) {
                                return true
                            }

                            return auth.some((role) => {
                                return roles.includes(role)
                            })
                        }),
                    },
                ]
            }, [])
            .filter(({
                links,
            }) => {
                return links.length > 0
            })
    }, [roles])

    return (
        <Menu
            items={allowedItems}
            selectedId={selectedId}
        />
    )
}

export default MenuContainer
