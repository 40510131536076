import {
    makeStyles,
} from 'app/tss'

const general = 'general'
const handover = 'handover'
const collection = 'collection'
const quoteDetails = 'quoteDetails'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${general} ${handover}"
                "${quoteDetails} ${collection}"
                `,
            gridTemplateColumns: 'repeat(2, 2fr)',
        },
        general: {
            gridArea: general,
            height: 'fit-content',
        },
        handover: {
            gridArea: handover,
        },
        collection: {
            gridArea: collection,
            height: 'fit-content',
        },
        quoteDetails: {
            gridArea: quoteDetails,
            height: 'fit-content',
        },
    }
})

export default useStyles
