import React, {
    useEffect,
} from 'react'
import moment from 'moment'

import StatusHandler from 'app/shared-components/StatusHandler'
import useGetLaneById from 'app/Apps/OrderManagement/hooks/useGetLaneById'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import FinalizeBookingStep from './FinalizeBookingStep'
import {
    FinalizeBookingStepType, Selection,
} from '../../createCustomerTransportForm.types'

const FinalizeBookingStepContainer = ({
    setActiveStep,
    onChange,
    value,
    index,
    laneId,
    onReset,
}: {
    value: FinalizeBookingStepType,
    onChange: (val: Partial<FinalizeBookingStepType>) => void,
    setActiveStep: (val: number) => void,
    onReset: () => void,
    index: number,
    laneId: number,
}) => {
    const {
        data: laneData,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLaneById(laneId)

    useEffect(() => {
        if (isDataReady && !value?.orderEntered) {
            onChange({
                orderEntered: moment().format('DD.MM.YYYY HH:mm ZZ'),
                originScSelection: Selection.DEFAULT,
                fwdPosSelection: Selection.DEFAULT,
                transportModeRoad: laneData?.transportModeRoad === TransportModeRoad.FTL_OR_LTL
                    ? TransportModeRoad.FTL
                    : laneData?.transportModeRoad,
                originSc: laneData?.originSc,
                originScBackup: laneData?.originScBackup,
                fwdPosBackup: laneData?.fwdPosBackup,
            })
        }
    }, [
        isDataReady,
        laneData?.transportModeRoad,
        laneData?.originSc,
        laneData?.originScBackup,
        laneData?.fwdPosBackup,
        onChange,
        value?.orderEntered,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady && !!value}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <FinalizeBookingStep
                setActiveStep={setActiveStep}
                onChange={onChange}
                value={value}
                index={index}
                laneData={laneData}
                onReset={onReset}
            />
        </StatusHandler>
    )
}

export default FinalizeBookingStepContainer
