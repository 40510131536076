import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../../internalTransport.request'
import {
    InternalTransportRequestParams,
} from '../CreateManuallyForm/createManuallyForm.types'

const useCreateInternalOrder = (onSuccess: ({
    orderNumber,
}: {orderNumber: string}) => void) => {
    const token = useJWTToken()
    const {
        onError,
    } = useHandleStatusRequest({
        id: 'CreateInternalOrder',
    })

    return useMutation({
        mutationFn: (data: Partial<InternalTransportRequestParams>) => {
            return request.Create.mutationFn({
                data,
                token,
            })
        },
        onSuccess,
        onError,
    })
}

export default useCreateInternalOrder
