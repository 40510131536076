import React, {
    useMemo,
} from 'react'
import {
    useUserRoles,
} from '@skycell-ag/auth'

import ActiveTab from './ActiveTab'

import useSelectedTab from './hooks/useSelectedTab'

const ActiveTabContainer = () => {
    const {
        icon, links, className,
    } = useSelectedTab()

    const roles = useUserRoles()

    const allowedLinks = useMemo(() => {
        return links.filter(({
            auth,
        }) => {
            return auth.some((role) => {
                return roles.includes(role)
            })
        })
    }, [
        links,
        roles,
    ])

    return (
        <ActiveTab
            icon={icon}
            links={allowedLinks}
            className={className}
        />
    )
}

export default ActiveTabContainer
