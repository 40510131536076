import {
    useCallback,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import omit from 'lodash/omit'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import filterLabelConverter from 'app/Apps/DomainObject/hooks/useLoadFilters/loadFilters/filterLabelConverter'
import {
    RequestConfig,
} from 'app/types/request.types'
import useGetUntypedEnumLabel from '../useEnumValues/useGetUntypedEnumLabel'
import {
    FilterState,
} from './useFilter'

type FilterCountResponseType = {
    filters: {
        count: number,
        label: string,
        value: string,
    }[]
}

type Params = {
    request: (...args: any[]) => RequestConfig,
    fieldName: string,
    allFilters: FilterState,
}

export default ({
    request,
    fieldName,
    allFilters,
}: Params) => {
    const token = useJWTToken()
    const getLabels = useGetUntypedEnumLabel()

    const {
        onError,
    } = useHandleStatusRequest({
        title: 'Something went wrong',
        id: 'FilterCountError',
    })

    const {
        mutateAsync: applyFilter,
    } = useMutation<FilterCountResponseType>({
        mutationFn: () => {
            const otherFilters = omit(allFilters, [`${fieldName}`])

            return simpleApi({
                ...request({
                    fieldName,
                }),
                data: {
                    includeFilters: {
                        ...otherFilters,
                    },
                },
                token,
            })
        },
        onError,
    })

    return useCallback(async () => {
        const data = await applyFilter()

        if (data) {
            return filterLabelConverter(getLabels)(data)
        }
        return []
    }, [
        applyFilter,
        getLabels,
    ])
}
