import React, {
    useState, useCallback,
} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    useSharedContext,
} from 'app/shared-components/SharedContext'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import {
    user,
} from '@skycell-ag/auth'
import useRemovePreBookingComment from '../hooks/useRemovePreBookingComment'

type RowProps = {
    createdBy: string,
    comment: string,
    id: number
}
const RemovePreBookingComment = ({
    rowProps,
}: {
    rowProps: RowProps,
}) => {
    const [
        confirmation,
        setConfirmation,
    ] = useState<boolean>(false)

    const {
        createdBy, comment, id,
    } = rowProps

    const refetch = useSharedContext<() => void>()

    const deleteComment = useRemovePreBookingComment({
        onSuccess: refetch,
    })

    const closeConfirmation = useCallback((positive) => {
        if (positive) {
            deleteComment(id)
        }
        setConfirmation(false)
    }, [
        id,
        deleteComment,
    ])

    const onRemoveClick = useCallback(() => {
        setConfirmation(true)
    }, [])

    return user?.data?.username === createdBy ? (
        <>
            <DeleteIcon
                data-testid="DeleteIcon"
                onClick={onRemoveClick}
            />
            <ConfirmationDialog
                isPositiveAlert
                positiveLabel="Delete Comment"
                negativeLabel="Cancel"
                open={!!confirmation}
                handleClose={closeConfirmation}
                dialogTitle="Delete Comment"
                dialogContent={`Are you sure you would like to permanently delete a comment "${comment}"?`}
            />
        </>
    ) : null
}

export default RemovePreBookingComment
