import {
    useCallback,
} from 'react'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import {
    FilteredBaseColumn,
} from 'app/types/richtable.types'
import Filter from 'app/shared-components/Filter'
import RangeFilter from 'app/shared-components/RangeFilter'
import DateRangeSelectorFilter from 'app/shared-components/DateRangeSelectorFilter'
import useGetUntypedEnumLabel from 'app/hooks/useEnumValues/useGetUntypedEnumLabel'

import loadFilters, {
    LoadFiltersParams,
} from './loadFilters'

const filterComponentMap = {
    array: Filter,
    range: RangeFilter,
    dayRange: RangeFilter,
    'date-range': DateRangeSelectorFilter,
}

const useLoadFilters = ({
    customUrl,
    load = loadFilters,
}:{
    customUrl: string,
    load?: (params: LoadFiltersParams)=> Promise<any>,
}) => {
    const getLabels = useGetUntypedEnumLabel()
    const token = useJWTToken()

    return useCallback(
        (columns: FilteredBaseColumn[]) => {
            return columns.map((column: FilteredBaseColumn) => {
                const {
                    filterField,
                    filterType,
                    FilterComponent,
                    rangeType = '',
                    customFilterItemLabelConverter,
                } = column

                const loadFiltersFn = filterField ? (params) => {
                    return load({
                        params: {
                            token,
                            columnInfo: column,
                            props: {
                                ...params,
                                type: rangeType,
                            },
                        },
                        customUrl,
                        getLabels: customFilterItemLabelConverter || getLabels,
                    })
                } : undefined

                return {
                    ...column,
                    headerProps: {
                        ...column.headerProps,
                        FilterComponent: FilterComponent || filterComponentMap[filterType],
                        filterField,
                        loadFilters: loadFiltersFn,
                    },
                }
            })
        },
        [
            token,
            customUrl,
            load,
            getLabels,
        ],
    )
}

export default useLoadFilters
