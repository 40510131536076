import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    Gateway,
    GatewayBoxConfiguration,
    GatewayDataPlan,
    GatewayLocationCategory,
    GatewayStatus, GatewayStorageArea, GatewayType,
} from './Gateways.types'

const getFields = ({
    showContactInformation = true,
    showConnectedLogger = true,
    showConnectionInfo = true,
    getAllowedValues,
    locationId = undefined,
    disabled = false,
}: {
    showContactInformation: boolean,
    showConnectedLogger: boolean,
    showConnectionInfo: boolean,
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    locationId: number,
    disabled: boolean,
}): DTOCardFormField<keyof Gateway>[] => {
    return [
        {
            id: 'gateway',
            title: 'Gateway',
            className: 'gateway',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'gatewayNumber',
                            labelKey: 'GatewayView.gatewayNumber',
                            disabled: true,
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'gatewayImeiMac',
                            labelKey: 'Gateway.gatewayImeiMac',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'gatewaySimCardNumber',
                            labelKey: 'Gateway.gatewaySimCardNumber',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'gatewaySimCardNumberBackup',
                            labelKey: 'GatewayCreateDto.gatewaySimCardNumberBackup',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'hardwareVersion',
                            labelKey: 'Gateway.hardwareVersion',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'softwareVersion',
                            labelKey: 'Gateway.softwareVersion',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'installedOn',
                            labelKey: 'Gateway.installedOn',
                            componentName: 'DateTimeSelect',
                        },
                        {
                            name: 'installedBy',
                            componentName: 'ContactObjectSelector',
                            labelKey: 'Gateway.installedBy',
                        },
                        {
                            name: 'supportingDocuments',
                            labelKey: 'Accounts.supportingDocuments',
                            componentName: 'AttachmentsObjectSelector',
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'gatewayType',
                            labelKey: 'Gateway.gatewayType',
                            className: 'gatewayType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(GatewayType),
                            useDropdown: true,
                        },
                        {
                            name: 'gatewayUuid',
                            labelKey: 'Gateway.gatewayUuid',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'gatewayConnectionType',
                            labelKey: 'Gateway.gatewayConnectionType', // TODO: new key
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(GatewayDataPlan),
                            useDropdown: true,
                        },
                        {
                            name: 'gatewayStatus',
                            labelKey: 'Gateway.gatewayStatus',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(GatewayStatus),
                            useDropdown: true,
                            required: true,
                        },
                        {
                            name: 'gatewayBoxConfiguration',
                            labelKey: 'Gateway.gatewayBoxConfiguration',
                            useDropdown: true,
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(GatewayBoxConfiguration),
                        },
                        {
                            name: 'project',
                            labelKey: 'Gateway.project',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'remark',
                            labelKey: 'Gateway.remark',
                            className: 'remark',
                            componentName: 'LongText',
                        },
                    ],
                },
            ],
        },
        {
            id: 'location',
            title: 'Location',
            className: 'location',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'location',
                            labelKey: 'Gateway.location',
                            componentName: 'LocationObjectDataSelector',
                            isShippingLocation: true,
                            required: true,
                        },
                        {
                            name: 'area',
                            labelKey: 'Gateway.area',
                            componentName: 'AreaSelector',
                            locationId,
                        },
                        {
                            name: 'locationRemark',
                            labelKey: 'Gateway.locationRemark',
                            componentName: 'InputSingleline',
                        },
                        {
                            name: 'gatewayStorageArea',
                            labelKey: 'Gateway.gatewayStorageArea',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(GatewayStorageArea),
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'latitude',
                            componentName: 'InputSingleline',
                            labelKey: 'Gateway.latitude',
                            required: true,
                        },
                        {
                            name: 'longitude',
                            componentName: 'InputSingleline',
                            labelKey: 'Gateway.longitude',
                            required: true,
                        },
                        {
                            name: 'gatewayLocationCategories',
                            labelKey: 'GatewayView.gatewayLocationCategories',
                            componentName: 'MultiEnumSelector',
                            disabled: true,
                            allowedValues: getAllowedValues(GatewayLocationCategory),
                        },
                    ],
                },
            ],
        },
        {
            title: 'Connection Information',
            className: 'connectionInfo',
            id: 'connectionInfo',
            hidden: !showConnectionInfo,
            fields: [{
                name: 'lastConnectedOn',
                disabled: true,
                labelKey: 'Gateway.lastConnectedOn',
                componentName: 'TemperatureAge',
            }],
        },
        {
            title: 'Gateway Contacts',
            className: 'contacts',
            id: 'contacts',
            hidden: !showContactInformation,
            fields: [disabled ? {
                name: 'gatewayContacts',
                componentName: 'GatewayContacts',
            } : {
                name: 'gatewayContacts',
                componentName: 'MultipleContactObjectSelector',
                value: [],
            }],
        },
        {
            title: 'Connected Loggers',
            className: 'loggers',
            id: 'loggers',
            hidden: !showConnectedLogger,
            fields: [{
                name: 'id',
                componentName: 'ConnectedLoggers',
            }],
        },
    ]
}

export default getFields
