import React, {
    useState,
} from 'react'
import Button from 'app/shared-components/Button'

import ClosePrebooking from 'app/Apps/OrderManagement/Orders/Components/ClosePrebooking'
import useOnSuccessCreateOrder from 'app/Apps/OrderManagement/Orders/hooks/useOnSuccessCreateOrder'
import {
    OrderTypes,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import useCreateCustomerTransportOrder from '../../../hooks/useCreateCustomerTransportOrder'
import {
    ClosePreBookingStep,
} from '../../createDirectLease.types'
import useStyles from './ClosePrebookingStep.style'

const ClosePrebookingStep = ({
    value,
    setActiveStep,
    setIsEdited,
    index,
}: {
    value: ClosePreBookingStep,
    setActiveStep: (val: number) => void,
    setIsEdited: (isEdited: boolean) => void,
    index: number,
}) => {
    const {
        classes,
    } = useStyles()

    const [
        selectedPrebooking,
        setSelectedPrebooking,
    ] = useState<number>(undefined)

    const onSuccessCreate = useOnSuccessCreateOrder(OrderTypes.CUSTOMER_TRANSPORT)

    const {
        isPending: isLoading,
        mutate,
    } = useCreateCustomerTransportOrder(onSuccessCreate)

    const onClickComplete = () => {
        setIsEdited(false)
        mutate({
            ...value,
            preBooking: selectedPrebooking ? {
                id: selectedPrebooking,
            } : undefined,
        })
    }

    const onClickBack = () => {
        setActiveStep(index - 1)
    }

    return (
        <div>
            <ClosePrebooking
                customerReference={value?.customerReference}
                mawb={value?.mawbNumber}
                selected={selectedPrebooking}
                setSelectedPrebooking={setSelectedPrebooking}
            />
            <div className={classes.actions}>
                <Button
                    label="Complete"
                    onClick={onClickComplete}
                    className={classes.button}
                    saving={isLoading}
                    data-testid="btn-complete"
                />
                <Button
                    label="Back"
                    secondary
                    onClick={onClickBack}
                    className={classes.button}
                    data-testid="btn-back"
                />
            </div>
        </div>
    )
}

export default ClosePrebookingStep
