import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    AccountLane,
} from '../../AccountLanes.types'

import requests from '../../AccountLanes.requests'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: 'New lanes were created',
        id: 'AccountLanesBulkCreate',
    })

    const {
        mutateAsync: createAccountLanes,
    } = useMutation({
        mutationFn: (lanes: AccountLane[]) => {
            return simpleApi({
                ...requests.bulkCreate({
                    data: {
                        lanes,
                    },
                }),
                token,
            })
        },
        onSuccess,
        onError,
    })

    return useCallback(async (lanes: AccountLane[], options: AsyncMutationOptions) => {
        if (!lanes?.length) {
            return onError({
                response: {
                    data: ['No lanes were added, please fill at least one row'],
                    statusCode: 500,
                },
            })
        }
        return createAccountLanes(lanes, options)
    }, [
        createAccountLanes,
        onError,
    ])
}
